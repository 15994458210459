import dayjs from "dayjs";

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

//Don't use this for security purposes. The generated string is not cryptographically secure.
export const generateRandomString = () => Math.random().toString(32).substring(2);

export const formatDateString = (dateStr: string | undefined): string => {
  if (!dateStr)
    return '';

  return dayjs(dateStr).format('YYYY/MM/DD');
}

export const extractURLParameters = (removeParamsFromUrl: boolean = true): (Record<string, string> | undefined) => {
  const [domain, params] =  window.location.href.split('?');
  const searchParams = new URLSearchParams(params); 
  const dic: Record<string, string> = {}; 
  searchParams.forEach((value, name) => {
      dic[name] = value;
  });

  if (Object.keys(dic).length > 0) {
      if (removeParamsFromUrl) 
        window.history.replaceState(null, '', domain);

      return dic;
  }
}

export const isEnumValIncludedInURLParams = <T> (record: Record<string, T>): boolean => {
  const urlParams = extractURLParameters(false);

  if (!urlParams) 
    return false;

  const paramVals = Object.values(urlParams);
  const recordVals = Object.values(record);

  return recordVals.some(val => paramVals.includes(String(val)));
}

export const clearURLParameters = () => {
  const [domain, params] =  window.location.href.split('?');
  const searchParams = new URLSearchParams(params);

  if (searchParams.set.length > 0) {
    window.history.replaceState(null, '', domain);
  }  
}