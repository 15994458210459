import { FunctionComponent, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import Text from '../text/Text';
import { useTranslation } from 'react-i18next';

interface LabelProps extends HTMLAttributes<HTMLDivElement> {
    required?: boolean;
    optional?: boolean;
}

const Container = styled.div`
  width: 100%;
  max-height: 40px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelShape = css`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 0 4px;
  margin-top: 1px;
  margin-right: 10px;
  border-radius: 10px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: #FFFFFF;
`;

const Required = styled(Text)`
  ${LabelShape}
  background: #F98686;
`;

const Optional = styled(Text)`
  ${LabelShape}
  background: #8A8E94;
`;

const Label: FunctionComponent<LabelProps> = ({
    required,
    optional,
    children,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            {required && (
                <Required>{t('common.required')}</Required>
            )}
            {optional && (
                <Optional>{t('common.optional')}</Optional>
            )}
            {children}
        </Container>
    );
};

export default Label;