import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { CONTENT_MAX_WIDTH } from '../../constants/styles';

interface PageProps extends HTMLAttributes<HTMLDivElement> {
}

const Container = styled.div`
  width: 100%;
  flex: 1;
  align-items: center;
  overflow: auto;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 10px 0 30px 0;
`;

const Page: FunctionComponent<PageProps> = ({
    children,
    ...props
}) => {
    return (
        <Container>
            <Content {...props}>
                {children}
            </Content>          
        </Container>
    );
};

export default Page;