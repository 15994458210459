import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  VisaAttachment,
  VisaAttachmentKind,
} from "../../../../../../types/visa/attachment";
import PreviewFileList from "../../../PreviewFileList";
import { extractVisaAttachments } from "../../../../../../utils/visaFormDataHelper";
import { PreviewHeading } from "../../previewBodyCommonStyle";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";

interface DependentSectionProps {
  visaApplicationType: VisaApplicationType; //Defined but not used
  visaAttachments: VisaAttachment[];
}

//This section is used just for the MVP so it is not necessary
//to handle different visa application types
export const DependentSection: FunctionComponent<DependentSectionProps> = ({
  visaAttachments,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "dependentSection",
  });

  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      <PreviewFileList
        attachments={[
          {
            label: t("form"),
            files: extractVisaAttachments(
              visaAttachments,
              VisaAttachmentKind.Application
            ),
          }
        ]}
      />
    </section>
  );
};

export default DependentSection;
