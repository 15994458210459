import { VisaAttachment, VisaAttachmentKind } from "../types/visa/attachment";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const uploadVisaAttachment = async (visaApplicationId: number, file: File, kind: VisaAttachmentKind) => {
  const data = new FormData();
  data.append('visa_application_id', visaApplicationId.toString());
  data.append('kind', kind);
  data.append('file', file);

  const response = await axiosInstance.request({
      method: 'POST',
      url: `visa_attachments`,
      data,
  });

  if(hasErrorStatus(response))
    throwRequestError('uploadVisaAttachment', response.status);

  return response.data as VisaAttachment;
};

export const getVisaAttachment = async (id: number) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `visa_attachments/${id}`
  });

  if(hasErrorStatus(response))
    throwRequestError('getVisaAttachment', response.status);

  return response.data as VisaAttachment;
};

export const updateVisaAttachment = async (visaAttachmentId: number, file: File, kind: VisaAttachmentKind) => {
  const data = new FormData();
  data.append('kind', kind);
  data.append('file', file);

  const response = await axiosInstance.request({
      method: 'PUT',
      url: `visa_attachments/${visaAttachmentId}`,
      data,
  });

  if(hasErrorStatus(response))
    throwRequestError('updateVisaAttachment', response.status);

  return response.data as VisaAttachment;
};

export const deleteVisaAttachment = async (visaAttachmentId: number) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `visa_attachments/${visaAttachmentId}`,
  });

  if(hasErrorStatus(response))
    throwRequestError('deleteVisaAttachment', response.status);

  return response.data as VisaAttachment;
};
