import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import styled from "styled-components";
import Button from "../../components/form/Button";
import Label from "../../components/form/Label";
import TextInput from "../../components/form/TextInput";
import Column from "../../components/layout/Column";
import Page from "../../components/layout/Page";
import PageHeading from "../../components/text/PageHeading";
import Text from "../../components/text/Text";
import { signIn } from "../../apis/auth";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";
import { User } from "../../types/user/data";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { extractUserRegistrationAppStatus } from "../../utils/userRegistrationHelper";

interface LoginPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 20px;
`;

const LocalLabel = styled(Label)`
  margin-bottom: 5px;
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  text-decoration: none;
`;

const ButtonContainer = styled.div`
  padding-top: 30px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogin = async () => {
    dispatcher.startLoading();
    try {
      const user: User = await signIn(email, password);
      const userRegistrationApplication = await getLatestUserRegistrationApplication();
      
      dispatcher.login();
      dispatcher.setUserRegistrationAppStatus(
        extractUserRegistrationAppStatus(userRegistrationApplication)
      );

      if (!!!user.status_of_residence || !!!user.user_setting.language) {
        navigate("/setup");
        return;
      };

      i18n.changeLanguage(user.user_setting.language.code);
      localStorage.setItem("lng", user.user_setting.language.code);
      dispatcher.completeSetup();
      dispatcher.showSnackbar(t("snackbar.loginSuccess"), "success");
      navigate("/");
    } catch (e) {
      dispatcher.showSnackbar(t("snackbar.loginFailed"), "warning");
    } finally {
      dispatcher.stopLoading();
    }

  };

  return (
    <LocalPage>
      <PageHeading>{t("loginPage.title")}</PageHeading>

      <Column>
        <LocalLabel>{t("loginPage.email")}</LocalLabel>
        <TextInput value={email} onValueChange={setEmail} />
      </Column>

      <Column>
        <LocalLabel>{t("loginPage.password")}</LocalLabel>
        <TextInput
          type="password"
          value={password}
          onValueChange={setPassword}
        />
      </Column>

      <ButtonContainer>
        <Column>
          <Button
            variant="primary"
            disabled={!email || !password}
            onClick={handleLogin}
          >
            {t("loginPage.login")}
          </Button>

          <Link href="/signup">
            <Text style={{ color: "#017698" }}>{t("loginPage.createAccount")}</Text>
          </Link>
        </Column>
      </ButtonContainer>
    </LocalPage>
  );
};

export default LoginPage;
