import { RELATIONSHIP_OPTIONS, MUNICIPALITY_OPTIONS, ALL_NATIONALITY_OPTIONS, PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT, PREFECTURE_OPTIONS, DESIRED_PERIOD_OF_STAY_FOR_DEPENDENT } from "../constants/options";
import { OFFICE_OPTIONS } from "../constants/options/office";
import { MAX_FAMILY_CORESIDENTS } from "../constants/values";
import { 
  ApplicationCategoryCode as ApplicationCategoryCodeForExtensionBySelf, 
  StatusOfResidenceCode as StatusOfResidenceCodeForExtensionBySelf
} from "../types/extensionBySelf/specificValues";
import { StatusOfResidenceOfUser } from "../types/user/data";
import { VisaAttachmentKind } from "../types/visa/attachment";
import { VisaAttachment } from "../types/visa/attachment";
import { CurrentStatusOfResidenceCode } from "../types/visa/currentStatusOfResidence";
import { CrimePunishmentStatus, FamilyInJapanOrCoresidents, Gender, HowToReceiveNewResidenceCard, LivingTogetherWithThisFamilyOrCoresident, MartialStatus } from "../types/visa/formCommonValues";


export const parseName = (name: string | undefined | null, delimiter: string)=> {
  const [familyName, givenName, ...middleArr] = (name ?? '').split(delimiter);
  return { 
    familyName: familyName ?? '', 
    givenName: givenName ?? '',
    middleName: middleArr.join(delimiter) ?? ''
  };
}

export const formatDate = (year: string | undefined, month: string | undefined, day: string | undefined, separator?: string) => {
  if (year && month && day) {
    return [year, month, day].join(separator ?? '/');
  }

  return '';
}

//This converts a short amount (e.g. 1) to a full amount (e.g. 1000)
//This sometimes appear in the form, for example, the amount of tax paid last year.
export const convertToFullAmount = (amount: string | undefined) => {
  if (!amount)
    return '';
  
  const converted = Number(amount);
  if (isNaN(converted)) 
    return ''

  return String(converted * 1000);
}

export const getTransKeyForGender = (gender: string) => {
  switch (gender) {
    case Gender.Male: return "male";
    case Gender.Female: return "female";
    default: return "";
  }
}

export const getTransKeyForMartialStatus = (martialStatus: string) => {
  switch (martialStatus) {
    case MartialStatus.Married: return "married";
    case MartialStatus.Single: return "single";
    default: return "";
  }
} 

export const getTransKeyForCrimePunishmentStatus = (crimePunishmentStatus: string) => {
  switch (crimePunishmentStatus) {
    case CrimePunishmentStatus.Punished: return "common.yes";
    case CrimePunishmentStatus.None: return "common.no";
    default: return "";
  }
}

export const getTransKeyForFamilyInJapanOrCohabitants = (familyInJapanOrCohabitants: string) => {
  switch (familyInJapanOrCohabitants) {
    case FamilyInJapanOrCoresidents.Present: return "common.yes";
    case FamilyInJapanOrCoresidents.None: return "common.no";
    default: return "";
  }
}

export const getTransKeyForLivingTogetherWithThisFamilyOrCohabitant = (livingTogetherWithThisFamilyOrCohabitant: string) => {
  switch (livingTogetherWithThisFamilyOrCohabitant) {
    case LivingTogetherWithThisFamilyOrCoresident.Yes: return "common.yes";
    case LivingTogetherWithThisFamilyOrCoresident.No: return "common.no";
    default: return "";
  }
}

export const getTransKeyForHowToReceiveNewResidenceCard = (howToReceiveNewResidenceCard: string) => {
  switch (howToReceiveNewResidenceCard) {
    case HowToReceiveNewResidenceCard.Mail: return "byMail";
    case HowToReceiveNewResidenceCard.VisitImmigrationOffice: return "pickUpAtImmigrationOffice";
    default: return "";
  }
}

export const getTransKeyForDesiredPeriodOfStay = (desiredPeriodOfStayCode: string) => {
  if (!desiredPeriodOfStayCode)
    return "";

  //e.g. "T440001000" -> "T44"
  const currentStatusOfResidenceCode = desiredPeriodOfStayCode.slice(0, 3);
  switch (currentStatusOfResidenceCode) {
    case CurrentStatusOfResidenceCode.Dependent: 
      return getLabelFromDesiredPeriodOfStayForDependent(
        desiredPeriodOfStayCode
      );
  
    default: 
      return "";
  }
}

export const getLabelFromPeriodOfStayCodeForDependent = (periodOfStayCode: string) => {
  return PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getLabelFromDesiredPeriodOfStayForDependent = (desiredPeriodOfStayCode: string) => {
  return DESIRED_PERIOD_OF_STAY_FOR_DEPENDENT.find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';
}

export const getLabelFromNationalityCode = (nationalityCode: string) => {
  return ALL_NATIONALITY_OPTIONS.find(op => op.value === nationalityCode)?.label ?? '';
}

//The purpose of this file is to provide util parsing methods 
//because we need to parse many values to get the corresponding values
export const getLabelsFromAddressCode = (addressCode: string) => {
  const prefectureCode = addressCode?.slice(0, 2);
  const municipalityCode = addressCode?.slice(2);

  return {
    prefecture: PREFECTURE_OPTIONS.find(op => op.value === prefectureCode)?.label ?? '',
    municipality: MUNICIPALITY_OPTIONS[prefectureCode]?.find(op => op.value === municipalityCode)?.label ?? ''
  }
}

//Pass a family-relation code (e.g. "01") to find out the corresponding label 
export const getLabelFromFamilyRelationCode = (familyRelationCode: string) => {
  return RELATIONSHIP_OPTIONS.find(op => op.value === familyRelationCode)?.label ?? '';
}

export const getLabelFromOfficeCode = ( officeCode: string, prefectureCode: string, municipalityCode: string) => {
  const options = OFFICE_OPTIONS[`${prefectureCode}${municipalityCode}`] 
    ?? OFFICE_OPTIONS[prefectureCode]
    ?? [];
  return options.find(op => op.value === officeCode)?.label ?? '';
}

//Pass the status_of_residence value (e.g. 'dependent') defined in the User object,
//and get the corresponding current status of residence code (*現に有する在留資格 - e.g. 'T61').
//This value is common to visa extension and change applications
export const convertToCurrentStatusOfResidenceCode = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return CurrentStatusOfResidenceCode.Dependent;

    default:
      return "";
  }
}

//Pass a current status of residence code (e.g. "T44") and return a translation key (e.g. "dependent")
export const convertCurrentStatusOfResidenceToTransKey = (statusOfResidenceCode: CurrentStatusOfResidenceCode | string) => {
  switch (statusOfResidenceCode) {
    case CurrentStatusOfResidenceCode.Dependent:
      return "dependent";
    
    default: 
      return "";
  }
}

//Pass the status_of_residence value (e.g. 'dependent') defined in the User object,
//and get the corresponding status of residence code (*在留資格 - e.g. '04R1') for the ExtensionBySelf type application
export const convertToStatusOfResidenceCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return StatusOfResidenceCodeForExtensionBySelf.Family;
    
    default:
      return "";
  }
}

//Pass the status_of_residence value (e.g. 'dependent') in the User object,
//and get the corresponding application category code (*申請種別 - e.g. '04R') for the ExtensionBySelf type application
export const convertToApplicationCategoryCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceOfUser) => {
  switch (statusOfResidence) {
    case StatusOfResidenceOfUser.Dependent:
      return ApplicationCategoryCodeForExtensionBySelf.Family;

    default:
      return "";
  }
}



//Expect to receive ExtensionBySelfData or ChangeBySelfData
//(these share the same properties used here)
export const getLengthOfZincSnzkList = <T> (data: T): number => {
  let length = 0;

  for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++)
  {
    const isThereValue = [
      data[`WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof T],
      data[`WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof T]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const extractVisaAttachments = (visaAttachments?: VisaAttachment [], kind?: VisaAttachmentKind): VisaAttachment[] => {
  if (!visaAttachments || !kind)
    return [];

  return visaAttachments.filter(va => va.kind === kind);
}

export const convertBytesToMB = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2); 
}