import { FunctionComponent, useEffect, useState } from "react";
import Page from "../../components/layout/Page";
import Row from "../../components/layout/Row";
import Text from "../../components/text/Text";
import PageHeading from "../../components/text/PageHeading";
import Section from "../../components/layout/Section";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { VisaApplication } from "../../types/visa/applicationData";
import { getVisaApplication } from "../../apis/visaApplication";
import {
  getVisaApplicationStatus,
  checkVisaApplicationType,
  getVisaInfoTransKeys,
  getVisaMessages,
} from "../../utils/visaApplicationHelper";
import BackButton from "../../components/navigation/BackButton";
import Column from "../../components/layout/Column";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import AppStatusLabel from "../../components/form/AppStatusLabel";
import VisaStatusConfirmationModal from "./VisaStatusConformationModal";
import { ReactComponent as AngleBracketIcon } from "../../assets/icon-angle-bracket-right-white.svg";
import { formatDateString, isEnumValIncludedInURLParams } from "../../utils/utils";
import PreviewModal from "../../components/modal/previewModal";
import { VisaApplicationType } from "../../types/visa/applicationType";
import dayjs from "dayjs";
import VisaMessageModal from "./VisaMessageModal";
import { NextAction as VisaStatusConfirmationModalAction } from "./VisaStatusConformationModal";
import { NextAction as VisaMessageModalAction } from "./VisaMessageModal";

interface ApplicationReviewPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const StatusHeadingWrapper = styled(Row)`
  justify-content: space-between;
`;

const ContentWrapper = styled(Section)`
  border-radius: 5px;
  padding: 30px 20px 30px 20px;
  gap: 30px;
`;

const DetailWrapper = styled(Column)`
  gap: 10px;
`;

const ResultText = styled(Text)`
  font-weight: 500;
`;

const SmallText = styled(Text)`
  font-size: 13px;
  color: #626161;
`;

const ButtonsWrapper = styled(Column)`
  gap: 30px;
`;

const LocalButton = styled(Button)`
  width: auto;
`;

const DateText = styled(Text)`
  font-size: 12px;
  color: #999999;
  white-space: "pre-wrap";
`;

const ForwardIcon = styled(AngleBracketIcon)`
  margin-left: 10px;
`;

const ApplicationReviewPage: FunctionComponent<
  ApplicationReviewPageProps
> = () => {
  const { dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [visaApplication, setVisaApplication] = useState<VisaApplication>();
  const [visaApplicationType, setVisaApplicationType] =
    useState<VisaApplicationType>();
  const [visaApplicationStatus, setVisaApplicationStatus] =
    useState<VisaApplicationStatus>(VisaApplicationStatus.Applying);
  const [submittedAt, setSubmittedAt] = useState<string>("");
  const [have30MinNotPassed, setHave30MinNotPassed] = useState<boolean>(true);
  const [
    isVisaStatusConfirmationModalOpen,
    setIsVisaStatusConfirmationModalOpen,
  ] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [headingText, setHeadingText] = useState<string>("");
  const onClickViewApplication = () => setShowPreviewModal(true);
  const onClickCheckResult = () => setIsVisaStatusConfirmationModalOpen(true);
  const onClickviewMessage = () => setShowMessageModal(true);
  const onStatusFetched = (status: VisaApplicationStatus) => {
    setVisaApplicationStatus(status);
  };

  useEffect(() => {
    (async () => {
      dispatcher.startLoading();
      try {
        const visaApplication = await getVisaApplication(id!);
        const visaApplicationType = checkVisaApplicationType(visaApplication);

        if (
          !!!visaApplication ||
          !!!visaApplicationType ||
          !!!visaApplication.last_submitted_visa_submission
        ) {
          throw new Error("Invalid or incomplete visa application");
        }

        setVisaApplication(visaApplication);
        setVisaApplicationType(visaApplicationType);
        setVisaApplicationStatus(
          getVisaApplicationStatus(visaApplication)
         
        );

        setHeadingText(
          t(
            `applicationReviewPage.category.${
              getVisaInfoTransKeys(visaApplication).type
            }`
          )
        );

        const submittedAt = dayjs(
          visaApplication.last_submitted_visa_submission.submitted_at
        );
        const now = dayjs();
        const diff = now.diff(submittedAt, "minute");
        setSubmittedAt(formatDateString(submittedAt.toString()));
        setHave30MinNotPassed(diff < 30);

        if (isEnumValIncludedInURLParams(VisaStatusConfirmationModalAction)) {
          setIsVisaStatusConfirmationModalOpen(true);
          return;
        }

        if (isEnumValIncludedInURLParams(VisaMessageModalAction)) {
         setShowMessageModal(true);
         return;
        }

      } catch (e) {
        navigate("/");
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <>
      <LocalPage>
        <BackButton onClick={() => navigate("/")} />
        <StatusHeadingWrapper>
          <PageHeading>{headingText}</PageHeading>
          {visaApplicationStatus && (
            <AppStatusLabel status={visaApplicationStatus} />
          )}
        </StatusHeadingWrapper>
        <ContentWrapper>
          {(() => {
            switch (visaApplicationStatus) {
              case VisaApplicationStatus.Applying:
                return (
                  <>
                    <DetailWrapper>
                      <ResultText>
                        {t("applicationReviewPage.applying.description1")}
                      </ResultText>
                      <Text>
                        {t("applicationReviewPage.applying.description2")}
                      </Text>
                    </DetailWrapper>
                    <ButtonsWrapper>
                      <Column style={{ gap: 5 }}>
                        <LocalButton
                          disabled={have30MinNotPassed}
                          variant="primary"
                          onClick={onClickCheckResult}
                        >
                          {t("applicationReviewPage.checkResult")}
                        </LocalButton>
                        {have30MinNotPassed && (
                          <SmallText>
                            {t(
                              "applicationReviewPage.applying.cannotBeChecked"
                            )}
                          </SmallText>
                        )}
                      </Column>
                      <LocalButton
                        variant="inline"
                        onClick={onClickViewApplication}
                      >
                        {t("applicationReviewPage.viewApplication")}
                      </LocalButton>
                    </ButtonsWrapper>
                  </>
                );

              case VisaApplicationStatus.Approved:
                return (
                  <>
                    <DetailWrapper>
                      <ResultText>
                        {t("applicationReviewPage.approved.description1")}
                      </ResultText>
                      <Text>
                        {t("applicationReviewPage.approved.description2")}
                      </Text>
                      <Text>
                        {t("applicationReviewPage.approved.description3")}
                      </Text>
                    </DetailWrapper>
                    <ButtonsWrapper>
                      <LocalButton
                        variant="inline"
                        onClick={onClickViewApplication}
                      >
                        {t("applicationReviewPage.viewApplication")}
                      </LocalButton>
                    </ButtonsWrapper>
                  </>
                );

              case VisaApplicationStatus.Denied:
                return (
                  <>
                    <DetailWrapper>
                      <ResultText>
                        {t("applicationReviewPage.denied.description1")}
                      </ResultText>
                      <Text>
                        {t("applicationReviewPage.denied.description2")}
                      </Text>
                      <Text color="#626161">
                        {t("applicationReviewPage.denied.description3")}
                      </Text>
                    </DetailWrapper>
                    <ButtonsWrapper>
                      <LocalButton
                        variant="primary"
                        onClick={onClickviewMessage}
                      >
                        {t("applicationReviewPage.checkMessages")}
                        <ForwardIcon />
                      </LocalButton>
                      <LocalButton
                        variant="inline"
                        onClick={onClickViewApplication}
                      >
                        {t("applicationReviewPage.viewApplication")}
                      </LocalButton>
                    </ButtonsWrapper>
                  </>
                );

              default:
                return null;
            }
          })()}
          {submittedAt && (
            <DateText>{`${t(
              "applicationReviewPage.submitted"
            )} ${submittedAt}`}</DateText>
          )}
        </ContentWrapper>
      </LocalPage>

      {isVisaStatusConfirmationModalOpen && visaApplication && (
          <VisaStatusConfirmationModal
            visaApplication={visaApplication}
            onClose={() => setIsVisaStatusConfirmationModalOpen(false)}
            onStatusFetched={onStatusFetched}
          />
        )}

      {showMessageModal && visaApplication && (
        <VisaMessageModal
          visaApplicationId={visaApplication.id}
          onClose={() => setShowMessageModal(false)}
        />
      )}

      {showPreviewModal && visaApplication && (
        <PreviewModal
          visaApplication={visaApplication}
          title={t("modalPreview.forApplicationReviewPage.title")}
          warningText={t("modalPreview.forApplicationReviewPage.warning")}
          onClickBackButton={() => setShowPreviewModal(false)}
          onClickPrimaryButton={() => setShowPreviewModal(false)}
          primaryButtonText={t("common.close")}
        />
      )}
    </>
  );
};

export default ApplicationReviewPage;
