import { FunctionComponent, useEffect, useState } from "react";
import FamilyVisaForm from "./VisaForms/FamilyVisaForm";
import Page from "../../components/layout/Page";
import Row from "../../components/layout/Row";
import Text from "../../components/text/Text";
import PageHeading from "../../components/text/PageHeading";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { VisaApplicationType } from "../../types/visa/applicationType";
import { CSVSourceKind, VisaApplication } from "../../types/visa/applicationData";
import { getVisaApplication, updateVisaApplication } from "../../apis/visaApplication";
import { checkVisaApplicationType, getVisaInfoTransKeys, isThisVisaApplicationSubmitted } from "../../utils/visaApplicationHelper";
import ApplicationSubmissionModal from "./ApplicationSubmissionModal";
import { extractURLParameters, isEnumValIncludedInURLParams } from "../../utils/utils";
import PaymentPromptModal from "../../components/modal/PaymentPromptModal";
import BackButton from "../../components/navigation/BackButton";
import { ReactComponent as OkIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { ReactComponent as FailedIcon } from "../../assets/icon-cross-red.svg";
import { ReactComponent as SubmitIcon } from "../../assets/icon-send-white.svg";
import UserAuthenticationModal from "./UserAuthenticationModal";
import { NextAction as AuthModalAction } from "./UserAuthenticationModal";
import { NextAction as SubmissionModalAction } from "./ApplicationSubmissionModal";
import { VisaAttachment, VisaAttachmentKind } from "../../types/visa/attachment";
import { updateExtensionBySelfData } from "../../apis/extensionBySelfData";
import { deleteVisaAttachment, uploadVisaAttachment } from "../../apis/visaAttachment";
import { ExtensionBySelfData } from "../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../types/changeBySelf/data";
import { updateChangeBySelfData } from "../../apis/changeBySelfData";
import Column from "../../components/layout/Column";
import Spinner from "../../components/display/Spinner";
import PreviewModal from "../../components/modal/previewModal";
import { getCurrentUser } from "../../apis/user";
import { isUserRegistrationApplicationApproved } from "../../utils/userRegistrationHelper";
import RasIDPasswordModal from "./RasIDPasswordModal";

interface ApplicationFormPageProps {}

const HeaderContainer = styled(Column)`
  padding: 20px;
  gap: 30px;
`;

const NavStatusContainer = styled(Row)`
  justify-content: space-between;
  gap: 15px;
`;

const HeadingButtonContainer = styled(Row)`
  justify-content: space-between;
`;

const AutoSaveStatusText = styled(Text)`
  color: #626161;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
`;

const ApplicationFormPage: FunctionComponent<ApplicationFormPageProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatcher } = useDispatcher();
  const [visaApplicationType, setVisaApplicationType] = useState<VisaApplicationType>();
  const [visaApplication, setVisaApplication] = useState<VisaApplication>();
  const [extensionBySelfData, setExtensionBySelfData] = useState<ExtensionBySelfData | null>(null);
  const [changeBySelfData, setChangeBySelfData] = useState<ChangeBySelfData | null>(null);
  const [visaAttachments, setVisaAttachments] = useState<VisaAttachment[]>([]);
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [hasDataBeenAutoSavedAtLeastOnce, setHasDataBeenAutoSavedAtLeastOnce] = useState<boolean>(false);
  const [haveAttachmentsBeenUpdatedAtLeastOnce, setHaveAttachmentsBeenUploadedAtLeastOnce] = useState<boolean>(false);

  //If true, the form shows alert icons next to the sections that have errors
  //In addition, empty required fields are treated as errors
  //(This is triggered by the user clicking the submit button)
  const [showAllErrorAlerts, setShowAllErrorAlerts] = useState<boolean>(false);

  const [hasFailedToSave, setHasFailedToSave] = useState<boolean>(false);
  const [hasUserPaid, setHasUserPaid] = useState<boolean>(false); 
  const [showSubmissionModal, setShowSubmissionModal] = useState<boolean>(false);
  const [showPaymentPromptModal, setShowPaymentPromptModal] = useState<boolean>(false);
  const [showUserAuthenticationModal, setShowUserAuthenticationModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showRasIdPasswordModal, setShowRasIdPasswordModal] = useState<boolean>(false);
  const [isApplicationReadyToSubmit, setIsApplicationReadyToSubmit] = useState<boolean>(false);


  const saveExtensionBySelfData = async (data: Partial<ExtensionBySelfData>) => {
    if (visaApplication?.csv_source_kind !== CSVSourceKind.ExtensionBySelf)
      throw new Error("The CSV source kind doesn't match the target data type that is being saved");

    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.koushin_honnin!.id;
      const updatedExtensionBySelfData: ExtensionBySelfData = await updateExtensionBySelfData(id, data);
      setExtensionBySelfData(updatedExtensionBySelfData);
      setVisaApplication({
        ...visaApplication!,
        koushin_honnin: updatedExtensionBySelfData
      })
      setHasDataBeenAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveChangeBySelfData = async (data: Partial<ChangeBySelfData>) => {
    if (visaApplication?.csv_source_kind !== CSVSourceKind.ChangeBySelf)
      throw new Error("The CSV source kind doesn't match the target data type that is being saved");

    try {
      setHasFailedToSave(false);
      dispatcher.startSaving();
      const id = visaApplication!.henkou_honnin!.id;
      const updatedChangeBySelfData: ChangeBySelfData = await updateChangeBySelfData(id, data);
      setChangeBySelfData(updatedChangeBySelfData);
      setVisaApplication({
        ...visaApplication!,
        henkou_honnin: updatedChangeBySelfData
      })
      setHasDataBeenAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endSaving();
    }
  }

  const saveVisaAttachment = async (file: File, kind: VisaAttachmentKind, uploaderId?: string) => {
    try {
      setHasFailedToSave(false);
      dispatcher.startUploading(uploaderId);
      const uploadedVisaAttachment = await uploadVisaAttachment(visaApplication!.id, file, kind);
      const newAttachments = [...visaAttachments, uploadedVisaAttachment];

      setVisaAttachments(newAttachments);
      setVisaApplication({
        ...visaApplication!,
        visa_attachments: newAttachments
      });
      setHaveAttachmentsBeenUploadedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endUploading(uploaderId);
    }
  }

  const removeVisaAttachment = async (visaAttachmentId: number) => {
    try {
      dispatcher.startSaving();
      await deleteVisaAttachment(visaAttachmentId);
      const newAttachments = visaAttachments.filter(va => va.id !== visaAttachmentId);

      setVisaAttachments(newAttachments);
      setVisaApplication({
        ...visaApplication!,
        visa_attachments: newAttachments
      });
      setHaveAttachmentsBeenUploadedAtLeastOnce(true);
    } catch (e) {
    } finally {
      dispatcher.endSaving();
    }
  }

  const onSubmit = () => {
    //Enable verify_to_submit to show all errors (even after logout and login again)
    if (!!!visaApplication?.verify_to_submit) {
      (async () => {
        try {
          updateVisaApplication(id!, { verify_to_submit: true });
        } catch (e) {
        } finally {
          setShowAllErrorAlerts(true);
        }
      })();
    }
    
    if (!isApplicationReadyToSubmit) {
      setShowAllErrorAlerts(true);
      dispatcher.showSnackbar(t("snackbar.enterAllFields"), 'warning');
      return;
    }

    if (!hasUserPaid) {
      setShowPaymentPromptModal(true);
      return;
    }
    
    if (!isUserRegistrationApplicationApproved(state.userRegistrationAppStatus)) {
      setShowUserAuthenticationModal(true)
      return;
    }

    //Submission modal is shown after the user confirms the data on the preview modal
    setShowPreviewModal(true);
  };


  useEffect(() => {
    (async () => {
      try {    
        dispatcher.startLoading();
        const visaApplication = await getVisaApplication(id!);
        const visaApplicationType = checkVisaApplicationType(visaApplication);
        const user = await getCurrentUser();

        if (isThisVisaApplicationSubmitted(visaApplication)) {
          navigate(`/application/${visaApplication.id}/review`);
          return;        
        }

        setVisaApplication(visaApplication);
        setVisaApplicationType(visaApplicationType);
        setPaymentUrl(user.stripe_invoice_url);
        setExtensionBySelfData(visaApplication.koushin_honnin);
        setChangeBySelfData(visaApplication.henkou_honnin);
        setVisaAttachments(visaApplication.visa_attachments);
        setHasUserPaid(user.is_paid);
        setShowAllErrorAlerts(visaApplication.verify_to_submit ?? false);

        if (isEnumValIncludedInURLParams(AuthModalAction)) {
          setShowUserAuthenticationModal(true);   
          return;
        }

        if (isEnumValIncludedInURLParams(SubmissionModalAction)) {
          setShowSubmissionModal(true);
          return;
        }

      } catch (e) {
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <Page>
      <HeaderContainer>
        <NavStatusContainer>
          <BackButton onClick={() => navigate("/")}/>     
          <AutoSaveStatusText>
            {/* Auto-save : *Initial state (show a note on auto-save for the user) */}
            { (!hasDataBeenAutoSavedAtLeastOnce && !haveAttachmentsBeenUpdatedAtLeastOnce) && 
              <>{t("applicationFormPage.autoSave.willBeAutoSaved")}</>
            }

            {/* Auto-save : Saving */}
            { ((hasDataBeenAutoSavedAtLeastOnce && state.isSaving) || 
               (haveAttachmentsBeenUpdatedAtLeastOnce && state.isUploading)) &&
              <><Spinner />{t("applicationFormPage.autoSave.saving")}</>
            }
              
            {/* Auto-save : Saved */}
            { ((hasDataBeenAutoSavedAtLeastOnce && !state.isSaving && !hasFailedToSave) || 
               (haveAttachmentsBeenUpdatedAtLeastOnce && !state.isUploading && !hasFailedToSave)) &&
              <><OkIcon />{t("applicationFormPage.autoSave.saved")}</>
            }

            {/* Auto-save : Failed to save */}
            { ((hasFailedToSave && !state.isSaving)) &&
              <><FailedIcon style={{ width: 20, height: 20 }}/>{t("applicationFormPage.autoSave.failed")}</>
            }
          </AutoSaveStatusText>
        </NavStatusContainer>
        <HeadingButtonContainer>
          <PageHeading>
            { (visaApplication && getVisaInfoTransKeys(visaApplication).type) &&
              t(`applicationFormPage.title.${getVisaInfoTransKeys(visaApplication).type}`) 
            }
          </PageHeading>
          <Button 
            style={{ minWidth: 120, width: 'auto', gap: 10 }}
            onClick={onSubmit}
          >
            <SubmitIcon />
            {t("common.submit")}
          </Button>
        </HeadingButtonContainer>
      </HeaderContainer>

      {/* Actual Form body */}
      <div>
        {(() => {
          switch (visaApplicationType) {
            case VisaApplicationType.ExtendFamilyVisaBySelf:
              return (
                <FamilyVisaForm 
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  visaAttachments={visaAttachments}              
                  onChangeBySelfDataChange={saveChangeBySelfData}
                  onExtensionBySelfDataChange={saveExtensionBySelfData}
                  onApplicationReadinessChange={setIsApplicationReadyToSubmit}
                  onVisaAttachmentUpload={saveVisaAttachment}
                  onVisaAttachmentDelete={removeVisaAttachment}
                  showErrorAlerts={showAllErrorAlerts}
                />
                );
            default:
              return null;
            }
          })()}
      </div>

      <ButtonWrapper>
        <Button
          variant="primary"
          style={{ gap: 10 }}
          onClick={onSubmit}
        >
          <SubmitIcon />
          {t("common.submit")}
        </Button>
      </ButtonWrapper>

      { showPaymentPromptModal &&
        <PaymentPromptModal 
          promptType="required"
          paymentUrl={paymentUrl}        
          onClose={() => setShowPaymentPromptModal(false)} 
        />
      }

      { showSubmissionModal && visaApplication &&
        <ApplicationSubmissionModal 
          visaApplication={visaApplication}
          params={extractURLParameters()}
          onClose={() => setShowSubmissionModal(false)} 
          onSubmissionComplete={() => {
            setShowSubmissionModal(false);
            navigate(`application/${id}/review`) 
          }}
        />
      }

      {/* User Authentication modal does two things
          1. Have the user confirm the current user registration status
          2. Ask the user to input RAS password and ID 
          
          The user can skip #1 if the user has already confirmed in the past
        */}
      { showUserAuthenticationModal && visaApplication &&
        <UserAuthenticationModal 
          visaApplicationId={visaApplication.id} 
          currentAppStatus={state.userRegistrationAppStatus}
          onClose={() => setShowUserAuthenticationModal(false)}    
          onClickNext={() => {
            setShowUserAuthenticationModal(false);
            setShowPreviewModal(true);
          }}
          onConfirmAuthStatus={status => {
            dispatcher.setUserRegistrationAppStatus(status);
          }}
        />
      }

      { showPreviewModal && visaApplication &&
        <PreviewModal 
          visaApplication={visaApplication}
          title={t("modalPreview.forApplicationFormPage.title")}
          description={t("modalPreview.forApplicationFormPage.description")}
          warningText={t("modalPreview.forApplicationFormPage.warning")}
          primaryButtonText={t("common.confirm")}
          onClickPrimaryButton={() => {
            setShowPreviewModal(false);
            setShowRasIdPasswordModal(true);
          }}
          onClickBackButton={() => setShowPreviewModal(false)}
      />
      }
      
      { showRasIdPasswordModal && 
        <RasIDPasswordModal
          onClose={() => setShowRasIdPasswordModal(false)}
          onBack={() => {
            setShowRasIdPasswordModal(false);
            setShowPreviewModal(true)
          }}
          onSave={() => {
            setShowSubmissionModal(true)
            setShowRasIdPasswordModal(false);
          }}
        />
      }

    </Page>
  );
};

export default ApplicationFormPage;
