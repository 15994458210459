export const PREFECTURE_OPTIONS = [
  {
      "label": "prefecture.hokkaido",
      "value": "01"
  },
  {
      "label": "prefecture.aomori",
      "value": "02"
  },
  {
      "label": "prefecture.iwate",
      "value": "03"
  },
  {
      "label": "prefecture.miyagi",
      "value": "04"
  },
  {
      "label": "prefecture.akita",
      "value": "05"
  },
  {
      "label": "prefecture.yamagata",
      "value": "06"
  },
  {
      "label": "prefecture.fukushima",
      "value": "07"
  },
  {
      "label": "prefecture.ibaraki",
      "value": "08"
  },
  {
      "label": "prefecture.tochigi",
      "value": "09"
  },
  {
      "label": "prefecture.gunma",
      "value": "10"
  },
  {
      "label": "prefecture.saitama",
      "value": "11"
  },
  {
      "label": "prefecture.chiba",
      "value": "12"
  },
  {
      "label": "prefecture.tokyo",
      "value": "13"
  },
  {
      "label": "prefecture.kanagawa",
      "value": "14"
  },
  {
      "label": "prefecture.niigata",
      "value": "15"
  },
  {
      "label": "prefecture.toyama",
      "value": "16"
  },
  {
      "label": "prefecture.ishikawa",
      "value": "17"
  },
  {
      "label": "prefecture.fukui",
      "value": "18"
  },
  {
      "label": "prefecture.yamanashi",
      "value": "19"
  },
  {
      "label": "prefecture.nagano",
      "value": "20"
  },
  {
      "label": "prefecture.gifu",
      "value": "21"
  },
  {
      "label": "prefecture.shizuoka",
      "value": "22"
  },
  {
      "label": "prefecture.aichi",
      "value": "23"
  },
  {
      "label": "prefecture.mie",
      "value": "24"
  },
  {
      "label": "prefecture.shiga",
      "value": "25"
  },
  {
      "label": "prefecture.kyoto",
      "value": "26"
  },
  {
      "label": "prefecture.osaka",
      "value": "27"
  },
  {
      "label": "prefecture.hyogo",
      "value": "28"
  },
  {
      "label": "prefecture.nara",
      "value": "29"
  },
  {
      "label": "prefecture.wakayama",
      "value": "30"
  },
  {
      "label": "prefecture.tottori",
      "value": "31"
  },
  {
      "label": "prefecture.shimane",
      "value": "32"
  },
  {
      "label": "prefecture.okayama",
      "value": "33"
  },
  {
      "label": "prefecture.hiroshima",
      "value": "34"
  },
  {
      "label": "prefecture.yamaguchi",
      "value": "35"
  },
  {
      "label": "prefecture.tokushima",
      "value": "36"
  },
  {
      "label": "prefecture.kagawa",
      "value": "37"
  },
  {
      "label": "prefecture.ehime",
      "value": "38"
  },
  {
      "label": "prefecture.kochi",
      "value": "39"
  },
  {
      "label": "prefecture.fukuoka",
      "value": "40"
  },
  {
      "label": "prefecture.saga",
      "value": "41"
  },
  {
      "label": "prefecture.nagasaki",
      "value": "42"
  },
  {
      "label": "prefecture.kumamoto",
      "value": "43"
  },
  {
      "label": "prefecture.oita",
      "value": "44"
  },
  {
      "label": "prefecture.miyazaki",
      "value": "45"
  },
  {
      "label": "prefecture.kagoshima",
      "value": "46"
  },
  {
      "label": "prefecture.okinawa",
      "value": "47"
  }
];