import {
  UserRegistration,
  UserRegistrationApplication,
  UserRegistrationSubmission,
} from "../types/userRegistration/data";
import {
  MynaFirstCardReadApiResponse,
  MynaSecondCardReadApiResponse,
} from "../types/userRegistration/response";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

//The user registration application is a table that contains the user registration data
//and any related metadata, such as user registration submission history.
//We only will need to call the Create and Get methods below.
//As of June 22, 2023, we only need to make one user registration application per user
//but backend allows to make multiple applications per user.
//Because of this, frontend will usually call `getUserRegistrationApplications` and then
//use the first element of the array to get the user registration application
export const createUserRegistrationApplication = async () => {
  const response = await axiosInstance.request({
    method: "POST",
    url: `riyousha_touroku_applications`,
  });

  if (hasErrorStatus(response))
    throwRequestError("createUserRegistrationApplication", response.status);

  return response.data as UserRegistrationApplication;
};

export const getUserRegistrationApplications = async () => {
  const response = await axiosInstance.request({
    method: "GET",
    url: `riyousha_touroku_applications`,
  });

  if (hasErrorStatus(response))
    throwRequestError("getUserRegistrationApplications", response.status);

  return response.data as UserRegistrationApplication[];
};

export const getUserRegistrationApplication = async (id: number | string) => {
  const response = await axiosInstance.request({
    method: "GET",
    url: `riyousha_touroku_applications/${id}`,
  });

  if (hasErrorStatus(response))
    throwRequestError("getUserRegistrationApplication", response.status);

  return response.data as UserRegistrationApplication;
};

//This API is a convenient way to get the latest UserRegistrationApplication record
export const getLatestUserRegistrationApplication = async () => {
  const response = await axiosInstance.request({
    method: "GET",
    url: `riyousha_touroku_applications/last`,
  });

  if (hasErrorStatus(response))
    throwRequestError("getCurrentUserRegistrationApplication", response.status);

  return response.data as UserRegistrationApplication;
};

export const updateUserRegistrationApplication = async (
  userRegistrationApplicationId: number | string,
  data: Partial<UserRegistrationApplication>
) => {
  const response = await axiosInstance.request({
    method: "PATCH",
    url: `riyousha_touroku_applications/${userRegistrationApplicationId}`,
    data,
  });

  if (hasErrorStatus(response))
    throwRequestError("updateUserRegistrationApplication", response.status);

  return response.data as UserRegistrationApplication;
};

export const deleteUserRegistrationApplication = async (
  userRegistrationApplicationId: number | string
) => {
  const response = await axiosInstance.request({
    method: "DELETE",
    url: `riyousha_touroku_applications/${userRegistrationApplicationId}`,
  });

  if (hasErrorStatus(response))
    throwRequestError("deleteUserRegistrationApplication", response.status);

  return response.data as UserRegistrationApplication;
};

/**************************************************************************/

//The user registration data is a table that contains the actual user registration data (e.g. name, address)
//Metadata such as submission history is not stored here

export const createUserRegistration = async (
  userRegistrationApplicationId: number | string
) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: `riyousha_tourokus`,
    data: {
      riyousha_touroku_application_id: userRegistrationApplicationId,
    },
  });

  if (hasErrorStatus(response))
    throwRequestError("createUserRegistration", response.status);

  return response.data as UserRegistration;
};

export const getUserRegistration = async (userRegistrationId: number | string) => {
  const response = await axiosInstance.request({
    method: "GET",
    url: `riyousha_tourokus/${userRegistrationId}`,
  });

  if (hasErrorStatus(response))
    throwRequestError("getUserRegistration", response.status);

  return response.data as UserRegistration;
};

export const updateUserRegistration = async (
  userRegistrationId: number | string,
  data: Partial<UserRegistration>
) => {
  const response = await axiosInstance.request({
    method: "PATCH",
    url: `riyousha_tourokus/${userRegistrationId}`,
    data,
  });

  if (hasErrorStatus(response))
    throwRequestError("updateUserRegistration", response.status);

  return response.data as UserRegistration;
};

export const deleteUserRegistration = async (userRegistrationId: number | string) => {
  const response = await axiosInstance.request({
    method: "DELETE",
    url: `riyousha_tourokus/${userRegistrationId}`,
  });

  if (hasErrorStatus(response))
    throwRequestError("deleteUserRegistration", response.status);

  return response.data as UserRegistration;
};

/**************************************************************************/

export const createUserRegistrationSubmission = async (userRegistrationApplicationId: number | string) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: `riyousha_touroku_submissions`,
    data: {
      riyousha_touroku_application_id: userRegistrationApplicationId,
    },
  });

  if (hasErrorStatus(response))
    throwRequestError("createUserRegistrationSubmission", response.status);

  return response.data as UserRegistrationSubmission;
};


/**************************************************************************/

//The following APIs are used to submit the user registration data to the Myna portal

//This API gets the URL to Myna App (1st time)
export const fetchFirstMynaURLToSubmitUserRegistrationApplication = async (
  userRegistrationSubmissionId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: "riyousha_touroku_submissions/submit_1",
    data: {
      riyousha_touroku_submission_id: userRegistrationSubmissionId,
      riyousha_touroku_comeback_path: 
        comebackPathAfterCardRead.startsWith('/') 
          ? comebackPathAfterCardRead
          : `/${comebackPathAfterCardRead}`
    },
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchFirstMynaURLToRegisterUser", response.status);

  return response.data as MynaFirstCardReadApiResponse;
};

//PRE-REQUISITE:
//(1) Before this API is called, the first card reading API must have already been called and the user already has read his/her card
//(2) This API should be called within 5 miniutes after the first card reading API is called; or
//    the user will fail to read the second card reading
export const fetchSecondMynaURLToSubmitUserRegistrationApplication = async (
  userRegistrationSubmissionId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: "riyousha_touroku_submissions/submit_2",
    data: {
      riyousha_touroku_submission_id: userRegistrationSubmissionId,
      riyousha_touroku_comeback_path: 
        comebackPathAfterCardRead.startsWith('/')
          ? comebackPathAfterCardRead
          : `/${comebackPathAfterCardRead}`
    },
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchSecondMynaURLToRegisterUser", response.status);

  return response.data as MynaSecondCardReadApiResponse;
};

//PRE-REQUISITES:
//(1) UserRegistration data must be already saved at backend
//(2) The user's Myna card has already been read twice
//(3) This API must be called within 5 minutes after the 2nd card reading, or the user will fail to submit
export const submitUserRegistrationApplication = async (
  userRegistrationSubmissionId: number | string
) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: "riyousha_touroku_submissions/submit_3",
    data: {
      riyousha_touroku_submission_id: userRegistrationSubmissionId,
    },
  });

  if (hasErrorStatus(response))
    throwRequestError("submitUserRegistration", response.status);

  return { status: "ok" };
};



/**************************************************************************/

//The following APIs are used to get the current status of the user registartion.
//Unlike visa appliaction statuses, the design only assumes two states: "pending" and "approved".
//Fetching the status is tricky as we have to call 4 APIs in total

//This API must be called before `fetchUserRegistarationStatus` and the user must read his/her card
export const fetchMynaURLToConfirmUserRegistrationStatus = async (
  userRegistrationApplicationId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'riyousha_touroku_submissions/show_1',
      data: {
        riyousha_touroku_application_id: userRegistrationApplicationId,
        riyousha_touroku_comeback_path: comebackPathAfterCardRead.startsWith('/')
          ? comebackPathAfterCardRead
          : `/${comebackPathAfterCardRead}`
      }
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchMynaURLToConfirmUserRegistrationStatus", response.status);

  return response.data as MynaFirstCardReadApiResponse;
};

//This API must be called after `fetchMynaURLToConfirmUserRegistrationStatus` and the user must have read his/her card
//Note: if 5 minutes have passed since the first card reading, the user will fail to get the result
export const fetchUserRegistrationStatus = async (
  userRegistrationSubmissionId: number | string
) => {
  let response = await axiosInstance.request({
    method: 'POST',
    url: 'riyousha_touroku_submissions/show_2'
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchUserRegistrationStatus : show_2", response.status);

  response = await axiosInstance.request({
    method: 'POST',
    url: 'riyousha_touroku_submissions/get_detail',
    data: {
      riyousha_touroku_submission_id: userRegistrationSubmissionId
    }
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchCurrentVisaApplicationStatus : get_detail", response.status);
  
  return response.data as UserRegistrationSubmission;
};