import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface SectionProps extends HTMLAttributes<HTMLDivElement> {
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  padding: 30px 20px 100px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  box-sizing: border-box;
`;

const Section: FunctionComponent<SectionProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default Section;