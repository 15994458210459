import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/layout/Section';
import MultiTextField from '../../../components/compound/MultiTextField';
import { ExtensionBySelfData } from '../../../types/extensionBySelf/data';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { ChangeBySelfData } from '../../../types/changeBySelf/data';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { useValidation } from '../../../hooks';

interface OtherSectionProps {
  visaApplicationType: VisaApplicationType;
  extensionBySelfData: ExtensionBySelfData | null;
  changeBySelfData: ChangeBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean; //Not used now but defined for future use
}

const OtherSection: FunctionComponent<OtherSectionProps> = ({
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  onChangeExtensionBySelfData,
  onChangeChangeBySelfData,
  onSectionReadinessChange
}) => {
  const { t } = useTranslation();
  const validation = useValidation();
  const [otherText, setOtherText] = useState('');
  const [otherTextError, setOtherTextError] = useState('');
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const saveExtensionBySelfData = (data: Partial<ExtensionBySelfData>) => onChangeExtensionBySelfData(data);
  const saveChangeBySelfData = (data: Partial<ChangeBySelfData>) => onChangeChangeBySelfData(data);

  useEffect(() => {
    if (isVisaExtension && extensionBySelfData)
      setOtherText(extensionBySelfData['WCIBS010Dto:lblFreeInputC'] || '');
    
    if (isVisaChange && changeBySelfData)
      setOtherText(changeBySelfData['WCICS020Dto:lblFreeInputC'] || '');
    
  }, [extensionBySelfData, changeBySelfData]);

  useEffect(() => {
    const areThereNoErrors = [
      otherTextError
    ].every(val => !val);

    onSectionReadinessChange(areThereNoErrors);
  }, [
    otherTextError
  ]);

  return (
    <Section>
      <MultiTextField
        label={t("otherSection.other")}
        value={otherText}
        error={otherTextError}
        maxLength={300}
        validators={[validation.createLengthValidator(300)]}
        note={t("otherSection.noteOnOther")}
        onValueChange={setOtherText}
        onErrorChange={setOtherTextError}
        onBlur={() => {
          if (isVisaExtension)
            saveExtensionBySelfData({ 'WCIBS010Dto:lblFreeInputC': otherText });
          
          if (isVisaChange)
            saveChangeBySelfData({ 'WCICS020Dto:lblFreeInputC': otherText });
        }}
      />
    </Section>
  );
};

export default OtherSection;
