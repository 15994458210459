import { FunctionComponent } from "react";
import Modal from "./Modal";
import Spinner from "../display/Spinner";

interface LoadingModalProps {}

const LoadingModal: FunctionComponent<LoadingModalProps> = () => {
  return (
    <Modal style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}>
      <Spinner/>
    </Modal>
  );
};

export default LoadingModal;
