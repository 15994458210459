import styled from "styled-components";
import Text from "../../../text/Text";
import Column from "../../../layout/Column";

export const LocalColumn = styled(Column)`
  align-items: normal;
  gap: 50px;
`;

export const PreviewHeading = styled(Text)`
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
  color: #444444;
  margin: 10px 0 10px 20px;
`;

export const PreviewSubHeading = styled(Text)`
  font-weight: 500;
  margin: 10px 0 10px 0;
`;

export const Indent = styled.div`
  padding-left: 20px;
  width: 100%;
`;
