import { FunctionComponent, useEffect } from 'react';
import Section from '../../../components/layout/Section';
import InlineMessage from '../../../components/form/InlineMessage';
import { VisaAttachment, VisaAttachmentKind } from '../../../types/visa/attachment';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import UploadField from '../../../components/compound/UploadField';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/form/Button';
import { ReactComponent as OutIcon } from '../../../assets/icon-external-site.svg';
import styled from 'styled-components';

interface DependentSectionProps {
    visaAttachments: VisaAttachment [],
    visaApplicationType: VisaApplicationType;
    onVisaAttachmentUpload: (file: File, kind: VisaAttachmentKind, uploaderId?: string) => void;
    onVisaAttachmentDelete: (visaAttachmentId: number) => void;
    onSectionReadinessChange: (isSectionReady: boolean) => void;
    showUnattachedAlert: boolean;
}

const Anchor = styled.a`
    display: inline-block;
    width: inherit;
    cursor: pointer;
    text-decoration: none;
`;

/* NOTE: This section is temporary and only for the MVP */
const DependentSection: FunctionComponent<DependentSectionProps> = ({
    visaAttachments,
    visaApplicationType,
    onVisaAttachmentUpload,
    onVisaAttachmentDelete,
    onSectionReadinessChange,
    showUnattachedAlert
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'dependentSection' });
    const linkToOfficialDoc = "https://www.moj.go.jp/isa/content/930004110.pdf";

    useEffect(() => {
        const requiredKinds = [
            VisaAttachmentKind.Application
          ];
        
        const givenKinds = new Set(visaAttachments.map(va => va.kind));
        onSectionReadinessChange(requiredKinds.every(rk => givenKinds.has(rk)));
    }, [visaAttachments])
    
    return (
        <Section>
            <InlineMessage variant='caution'>
                <div>
                    <div>{t("instruction1")}</div>
                    <ul style={{ paddingLeft: 25 }}>
                        <li>{t("instruction1_1")}</li>
                        <li>{t("instruction1_2")}</li>
                    </ul>
                    <div>{t("instruction2")}</div>
                </div>
            </InlineMessage>


            {/* Form */}
            <UploadField
                required
                label={t("form")}
                additionalButton={
                    <Anchor
                        href={linkToOfficialDoc}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button variant='inline'>
                            {t("viewForm")}
                            <OutIcon style={{ marginLeft: 5 }} />
                        </Button>
                    </Anchor>
                }
                uploadButtonText={t("uploadForm")}
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.Application}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showUnattachedAlert}
            />
            
        </Section>
    );
};

export default DependentSection;