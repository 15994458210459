import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { ReactComponent as SuccessIcon } from "../../assets/icon-checked.svg";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher, useValidation } from "../../hooks";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values";
import EmailAnchor from "../../components/text/EmailAnchor";
import { MUNICIPALITY_OPTIONS, PREFECTURE_OPTIONS } from "../../constants/options";
import { extractURLParameters } from "../../utils/utils";
import { UserRegistration} from "../../types/userRegistration/data";
import { Gender, TermsOfUseAgreement, UserCategory } from "../../types/userRegistration/value";
import { 
  createUserRegistration, 
  createUserRegistrationApplication, 
  createUserRegistrationSubmission, 
  fetchFirstMynaURLToSubmitUserRegistrationApplication, 
  fetchSecondMynaURLToSubmitUserRegistrationApplication, 
  getLatestUserRegistrationApplication, 
  submitUserRegistrationApplication, 
  updateUserRegistration 
} from "../../apis/userRegistration";
import TextField from "../../components/compound/TextField";
import RadioField from "../../components/compound/RadioField";
import DateSelectField from "../../components/compound/DateSelectField";
import InlineMessage from "../../components/form/InlineMessage";
import SelectField from "../../components/compound/SelectField";
import MultiTextField from "../../components/compound/MultiTextField";
import Checkbox from "../../components/form/Checkbox";
import { getCurrentUser } from "../../apis/user";
import { UserRegistrationApplicationStatus } from "../../types/userRegistration/applicationStatus";
import { isUserRegistrationApplicationDenied } from "../../utils/userRegistrationHelper";
import Spinner from "../../components/display/Spinner";

interface OnboardingPageProps {}

interface OnEvent {
  clickNext: () => void;
  clickBack: () => void;
}

interface MessageContentProps {
  contentType: ContentType;
  onEvent: OnEvent;
}

enum ContentType {
  Welcome,
  UserAuthenticationRequest,
  TellUsAboutYourself1,
  TellUsAboutYourself2,
  DownloadMynaApp,
  ReadMynaCard1stTime,
  ReadMynaCard2ndTime,
  Processing,
  UserAuthenticationRequested
}

enum NextAction {
  //Those strings are used to know which screen to show 
  //when the user comes back to the onboarding page after a card reading.
  //Since the parameter text is visible to the user, chose a kind of unclear text now temporarily
  //It will be okay to change the text to something else later
  Request2ndMynaURLToRead = "cfmusrath",
  RequestToSubmitUserAuth = "reqsbmtusrath",
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
`;

const NavContainer = styled.nav`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LocalColumn = styled(Column)`
  gap: 20px;
`;

const FieldWrapper = styled(Column)`
  padding-top: 10px;
  gap: 50px;
`;

const ResidenceFieldWrapper = styled(Column)`
  padding: 20px;
  background-color: #ededed;
  border-radius: 3px;
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const ButtonContainer = styled.div`
  margin-top: 78px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const NavText = styled(Text)`
  font-size: 15px;
  color: #999999;
`;

const LinkAnchor = styled.a`
  color: #017698;
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 14px;
    color: #444444;
  }
`;

const SupplementaryTextContainer = styled.div``;

const VerifyingResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
`;

const AppAnchor = styled.a`
  color: #017698;
`;

const LocalButton = styled(Button)`
  min-width: 280px;
  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    width: 100%;
  }
`;

const MessageContent = ({ contentType, onEvent }: MessageContentProps) => {
  const { t } = useTranslation();
  const { dispatcher } = useDispatcher();
  const validation = useValidation();
  const welcome = "onboardingPage.welcome";
  const authReq = "onboardingPage.userAuthRequest"
  const tellUs1 = "onboardingPage.tellUsAboutYourself1";
  const tellUs2 = "onboardingPage.tellUsAboutYourself2";
  const mynaApp = "onboardingPage.downloadMynaApp";
  const myna1st = "onboardingPage.readMynaCard1stTime";
  const myna2nd = "onboardingPage.readMynaCard2ndTime";
  const processing = "onboardingPage.processing";
  const reqAuth = "onboardingPage.authRequested";
  const isEmpty = (v: any) => (typeof v === "boolean" ? false : !!!v);
  const isNotEmpty = (v: any) => (typeof v === "boolean" ? true : !!v);
  const [userRegistrationId, setUserRegistrationId] = useState("");

  //To find out if the user focuses on any input field now
  //Used to control if the next button should be disabled
  const [isUserInputting, setIsUserInputting] = useState(false);
  
  //TODO: Currently, we use this fixed value.
  //In the future, we will change this value based on the user input
  const [userCategory] = useState(UserCategory.ForeignerSelf);
  
  //For "Tell us about yourself (1/2)" Form
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [faimilyNameError, setFamilyNameError] = useState("");
  const [givenNameError, setGivenNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const firstHalfData: Partial<UserRegistration> = {
    "WCFDS010Dto:hdnUserInfoName": `${familyName} ${givenName} ${middleName}`,
    "WCFDS010Dto:hdnSex": gender,
    "WCFDS010Dto:hdnDateOfBirthYear": birthdate.split("-")[0] ?? "",
    "WCFDS010Dto:hdnDateOfBirthMonth": birthdate.split("-")[1] ?? "",
    "WCFDS010Dto:hdnDateOfBirthDay": birthdate.split("-")[2] ?? "",
    "WCFDS010Dto:txtUserTelNo": phoneNumber,
    "WCFDS010Dto:txtMailAddress": email,
    "WCFDS010Dto:txtMailAddressChk": email,
  };
  const isNoErrorInFirstHalfData = [
    faimilyNameError,
    givenNameError,
    middleNameError,
    phoneNumberError,
    emailError
  ].every((value) => isEmpty(value));
  const isNoEmptyValuesInFirstHalfData = [
    familyName,
    givenName,
    gender,
    birthdate,
    phoneNumber,
    email
  ].every((value) => isNotEmpty(value));

  //For "Tell us about yourself (2/2)" Form
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [townStreetApartment, setTownStreetApartment] = useState("");
  const [residenceCardNum, setResidenceCardNum] = useState("");
  const [termsOfUseAgreement, setTermsOfUseAgreement] = useState("");
  const [prefectureError] = useState("");
  const [municipalityError] = useState("");
  const [townStreetApartmentError, setTownStreetApartmentError] = useState("");
  const [residenceCardNumError, setResidenceCardNumError] = useState("");
  const secondHalfData: Partial<UserRegistration> = {
    "WCFDS010Dto:hdnSearchedAddress": `${prefecture}${municipality}`,
    "WCFDS010Dto:txtDetailHomeAddress": townStreetApartment,
    "WCFDS010Dto:txtZiryCardNum": residenceCardNum,
    "WCFDS010Dto:chkDui": termsOfUseAgreement,
  };
  const isNoErrorInSecondHalfData = [
    prefectureError,
    municipalityError,
    townStreetApartmentError,
  ].every((value) => isEmpty(value));
  const isNoEmptyValuesInSecondHalfData = [
    prefecture,
    municipality,
    townStreetApartment,
    termsOfUseAgreement
  ].every((value) => isNotEmpty(value));

  const tryToSaveThenGoNext = async (data: Partial<UserRegistration>) => {
    dispatcher.startLoading();
    try {
      await updateUserRegistration(
        userRegistrationId,
        {
          "WCFDS010Dto:selUserKbn": userCategory,
          ...data
        }
      );
      onEvent.clickNext();
    } catch (e) {
    } finally {
      dispatcher.stopLoading();
    }
  };

  useEffect(() => {
    //If the user has already started reading Myna card,
    //No need to fetch the registration data as the user cannot go back to the form
    if (contentType > ContentType.ReadMynaCard1stTime) return;

    (async () => {
      try {
        dispatcher.startLoading();
        const user = await getCurrentUser();
        let userRegistrationApplication = await getLatestUserRegistrationApplication();
        let userRegistration = userRegistrationApplication?.riyousha_touroku;

        if (!!!userRegistrationApplication) 
          userRegistrationApplication = await createUserRegistrationApplication();
         
        if (!!!userRegistrationApplication.riyousha_touroku)
          userRegistration = await createUserRegistration(userRegistrationApplication.id);

        const data: UserRegistration = userRegistration;
        const [familyName, givenName, ...middleArr] = (data["WCFDS010Dto:hdnUserInfoName"] ?? "").split(" ");
        setUserRegistrationId(String(data.id));

        setEmail(data["WCFDS010Dto:txtMailAddress"] ?? user.email);
        setFamilyName(familyName ?? "");
        setGivenName(givenName ?? "");
        setMiddleName(middleArr.join(" ") ?? "");
        setGender(data["WCFDS010Dto:hdnSex"] ?? "");
        setBirthdate(
          [
            data["WCFDS010Dto:hdnDateOfBirthYear"] ?? "",
            data["WCFDS010Dto:hdnDateOfBirthMonth"] ?? "",
            data["WCFDS010Dto:hdnDateOfBirthDay"] ?? ""
          ].join("-")
        );
        setPhoneNumber(data["WCFDS010Dto:txtUserTelNo"] ?? "");
        setPrefecture(data["WCFDS010Dto:hdnSearchedAddress"]?.slice(0, 2) ?? "");
        setMunicipality(data["WCFDS010Dto:hdnSearchedAddress"]?.slice(2) ?? "");
        setTownStreetApartment(data["WCFDS010Dto:txtDetailHomeAddress"] ?? "");
        setResidenceCardNum(data["WCFDS010Dto:txtZiryCardNum"] ?? "");
        setTermsOfUseAgreement(data["WCFDS010Dto:chkDui"] ?? "");
      } catch (e) {
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);


  switch (contentType) {
    //"Welcome to Visadas" - ビザダスへようこそ
    //(This is the first screen the user sees after sign-up)
    case ContentType.Welcome:
      return (
        <>
          <LocalColumn>
            <HeadingText>{t(`${welcome}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text>{t(`${welcome}.description`)}</Text>
            </MessageTextContainer>
            <StepTextContainer style={{ backgroundColor: "#FFF3C9" }}>
              <Text style={{ textDecoration: "underline", marginBottom: 10 }}>
                {t(`${welcome}.youWillNeed`)}
              </Text>
              <LocalOl>
                <LocalLi style={{ marginBottom: 10 }}>
                  <Text>{t(`${welcome}.item1`)}</Text>
                  <Text style={{ marginTop: 5 }}>
                    <LinkAnchor
                      href={"https://www2.jpki.go.jp/prepare/pdf/nfclist.pdf"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(`${welcome}.compatibleModels`)}
                    </LinkAnchor>
                  </Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${welcome}.item2`)}</Text>
                  <Text style={{ marginTop: 5 }}>
                    <LinkAnchor
                      href={
                        "https://www.kojinbango-card.go.jp/apprec/apply/online_apply/"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(`${welcome}.retrieveHere`)}
                    </LinkAnchor>
                  </Text>
                </LocalLi>
              </LocalOl>
            </StepTextContainer>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t(`${welcome}.start`)}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"User authentication request" - マイナンバーカード認証
    //(This is the first screen the user sees when he/she needs 
    //to re-submit the application through the onboarding flow)
    case ContentType.UserAuthenticationRequest:
      return (
        <>
          <LocalColumn>
            <HeadingText>{t(`${authReq}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text>{t(`${authReq}.description`)}</Text>
            </MessageTextContainer>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t(`${welcome}.start`)}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"Tell us about yourself (1/2)" - 基本情報(1/2)
    case ContentType.TellUsAboutYourself1:
      return (
        <>
          <NavContainer>
            <BackButton onClick={onEvent.clickBack} />
            <NavText>{t(`${tellUs1}.step1/4`)}</NavText>
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${tellUs1}.title`)}</HeadingText>
            <Text>{t(`${tellUs1}.description`)}</Text>
            <InlineMessage variant="caution">
              <Text>{t(`${tellUs1}.caution`)}</Text>
            </InlineMessage>

            <FieldWrapper>
              {/* Family Name */}
              <TextField
                required={true}
                label={t(`${tellUs1}.familyName`)}
                placeholder="Nguyen"
                value={familyName}
                error={faimilyNameError}
                restriction={t("inputNote.halfwidth")}
                maxLength={34}
                validators={[validation.isAlpha]}
                onValueChange={setFamilyName}
                onErrorChange={setFamilyNameError}
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />

              {/* Given Name */}
              <TextField
                required={true}
                label={t(`${tellUs1}.givenName`)}
                placeholder="Liem"
                value={givenName}
                error={givenNameError}
                restriction={t("inputNote.halfwidth")}
                maxLength={34}
                validators={[validation.isAlpha]}
                onValueChange={setGivenName}
                onErrorChange={setGivenNameError}   
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />

              {/* Middle Name */}
              <TextField
                optional={true}
                label={t(`${tellUs1}.middleName`)}
                placeholder="Nguyen"
                value={middleName}
                error={middleNameError}
                restriction={t("inputNote.halfwidth")}
                maxLength={34}
                validators={[validation.isAlpha]}
                onValueChange={setMiddleName}
                onErrorChange={setMiddleNameError}   
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />

              {/* Gender */}
              <RadioField
                required={true}
                label={t(`${tellUs1}.gender`)} 
                options={[
                  {
                    label: t(`${tellUs1}.male`),
                    value: Gender.Male
                  },
                  {
                    label: t(`${tellUs1}.female`),
                    value: Gender.Female
                  },
                ]}
                value={gender}
                onValueChange={setGender}
              />

              {/* Birth date */}
              <DateSelectField
                required={true}
                label={t(`${tellUs1}.birthDate`)}
                value={birthdate}
                onValueChange={setBirthdate}
              />
              
              {/* Phone number */}              
              <TextField
                required={true}
                label={t(`${tellUs1}.phoneNumber`)}
                placeholder="09012345678"
                maxLength={12}
                value={phoneNumber}
                validators={[
                  validation.isNumeric,
                  validation.createLengthValidator(12),
                ]}
                error={phoneNumberError}
                restriction={t("inputNote.halfwidthNumberNoHyphens")}
                onValueChange={setPhoneNumber}
                onErrorChange={setPhoneNumberError}
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />

              {/* Email for contact */}
              <TextField
                required={true}
                label={t(`${tellUs1}.emailForContact`)}
                placeholder="visadas@gmail.com"
                value={email}
                error={emailError}
                restriction={t("inputNote.halfwidthLetterAndNumber")}
                maxLength={60}
                validators={[validation.isEmailAddress]}
                onValueChange={setEmail}
                onErrorChange={setEmailError}
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />

            </FieldWrapper>
          </LocalColumn>

          <ButtonContainer>
            <LocalButton
              variant="primary"
              disabled={
                isUserInputting ||
                !isNoEmptyValuesInFirstHalfData ||
                !isNoErrorInFirstHalfData
              }
              onClick={() => {
                tryToSaveThenGoNext(firstHalfData);
              }}
            >
              {t("common.next")}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"Tell us about yourself (2/2)" - 基本情報(2/2)
    case ContentType.TellUsAboutYourself2:
      return (
        <>
          <NavContainer>
            <BackButton onClick={onEvent.clickBack} />
            <NavText>{t(`${tellUs2}.step1/4`)}</NavText>
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${tellUs2}.title`)}</HeadingText>
            <Text>{t(`${tellUs2}.description`)}</Text>

            <FieldWrapper>
              <ResidenceFieldWrapper>
                <Text style={{ fontWeight: 500 }}>
                  {t(`${tellUs2}.currentResidence`)}
                </Text>
                {/* Prefecture */}
                <SelectField
                  required
                  label={t(`${tellUs2}.prefecture`)}
                  placeholder={t("placeholder.select")}
                  options={PREFECTURE_OPTIONS}
                  value={prefecture}
                  error={prefectureError}
                  onValueChange={setPrefecture}
                />
              
                {/* Municipality */}
                <SelectField
                  required
                  label={t(`${tellUs2}.municipality`)}
                  disabled={!!!prefecture}
                  placeholder={t("placeholder.select")}
                  options={prefecture ? MUNICIPALITY_OPTIONS[prefecture] : []}
                  value={municipality}
                  error={prefecture ? '' : t(`${tellUs2}.enterPrefectureFirst`)}
                  onValueChange={setMunicipality}
                />
                
                {/* Town, street, Apartment */}
                <MultiTextField
                  required
                  label={t(`${tellUs2}.townStreetApartment`)}
                  placeholder="南町１−２−３ビザダスアパート１０１号室"
                  value={townStreetApartment}
                  error={townStreetApartmentError}
                  maxLength={80}
                  validators={[
                    validation.isFullwidth,
                    validation.createLengthValidator(80),
                  ]}
                  onValueChange={setTownStreetApartment}
                  onErrorChange={setTownStreetApartmentError}
                  restriction={t("inputNote.fullwidthJapanese")}
                  onFocus={() => setIsUserInputting(true)}
                  onBlur={() => setIsUserInputting(false)}
                />
              </ResidenceFieldWrapper>

              {/* Residence Card Number */}
              <TextField
                required
                label={t(`${tellUs2}.residenceCardNumber`)}
                placeholder="AAAA9999999C"
                value={residenceCardNum}
                error={residenceCardNumError}
                restriction={t("inputNote.halfwidthLetterAndNumber")}
                maxLength={12}
                validators={[
                  validation.isAlphanumeric,
                  validation.createLengthValidator(12),
                ]}
                onValueChange={setResidenceCardNum}
                onErrorChange={setResidenceCardNumError}
                onFocus={() => setIsUserInputting(true)}
                onBlur={() => setIsUserInputting(false)}
              />      

              <Column style={{ gap: 10 }}>
                <Text>
                  <LinkAnchor
                    href={"https://www.moj.go.jp/isa/applications/guide/online-riyoukiyaku.html"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(`${tellUs2}.termsOfUse`)}
                  </LinkAnchor>
                </Text>
                
                <Checkbox
                  required
                  value={TermsOfUseAgreement.Agree}
                  checked={termsOfUseAgreement === TermsOfUseAgreement.Agree}
                  onValueChange={(checked) => {
                    setTermsOfUseAgreement(checked 
                      ? TermsOfUseAgreement.Agree 
                      : TermsOfUseAgreement.Disagree)
                  }}
                >
                  {t(`${tellUs2}.iAgree`)}
                </Checkbox>
              </Column>
              
            </FieldWrapper>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton
              variant="primary"
              disabled={
                isUserInputting ||
                !isNoEmptyValuesInFirstHalfData ||
                !isNoEmptyValuesInSecondHalfData ||
                !isNoEmptyValuesInSecondHalfData ||
                !isNoErrorInSecondHalfData ||
                termsOfUseAgreement !== TermsOfUseAgreement.Agree
              }
              onClick={() => {
                tryToSaveThenGoNext(secondHalfData);
              }}
            >
              {t("common.next")}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"Download Myna Portal App" - マイナポータルアプリをダウンロード
    case ContentType.DownloadMynaApp:
      return (
        <>
          <NavContainer>
            <BackButton onClick={onEvent.clickBack} />
            <NavText>{t(`${mynaApp}.step2/4`)}</NavText>
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${mynaApp}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text style={{ display: "block" }}>
                <Trans
                  i18nKey={t(`${mynaApp}.description1`)}
                  components={{
                    iosLink: (
                      <AppAnchor
                        href="https://apps.apple.com/jp/app/%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%9D%E3%83%BC%E3%82%BF%E3%83%AB/id1476359069"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link to Myna app (iOS version)
                      </AppAnchor>
                    ),
                    androidLink: (
                      <AppAnchor
                        href="https://play.google.com/store/apps/details?id=jp.go.cas.mpa&hl=ja&gl=US&pli=1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Lind to Myna app (Android version)
                      </AppAnchor>
                    ),
                  }}
                />
              </Text>
              <Text>{t(`${mynaApp}.description2`)}</Text>
            </MessageTextContainer>
            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t("common.next")}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"Verify your My Number Card (1st time)" - マイナンバーカードの読み取り（１回目）
    case ContentType.ReadMynaCard1stTime:
      return (
        <>
          <NavContainer>
            <BackButton onClick={onEvent.clickBack} />
            <NavText>{t(`${myna1st}.step3/4`)}</NavText>
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${myna1st}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text>{t(`${myna1st}.description1`)}</Text>
              <Text>{t(`${myna1st}.description2`)}</Text>
            </MessageTextContainer>
            <StepTextContainer>
              <LocalOl>
                <LocalLi>
                  <Text>{t(`${myna1st}.step1`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna1st}.step2`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna1st}.step3`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna1st}.step4`)}</Text>
                </LocalLi>
              </LocalOl>
            </StepTextContainer>
            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t("common.launch")}
            </LocalButton>
          </ButtonContainer>
        </>
      );


    //"Verify your My Number Card (2nd time)" - マイナンバーカードの読み取り（２回目）
    case ContentType.ReadMynaCard2ndTime:
      return (
        <>
          <NavContainer>
            <NavText>{t(`${myna2nd}.step4/4`)}</NavText>
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${myna2nd}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text>{t(`${myna2nd}.description1`)}</Text>
              <Text>{t(`${myna2nd}.description2`)}</Text>
            </MessageTextContainer>
            <StepTextContainer>
              <LocalOl>
                <LocalLi>
                  <Text>{t(`${myna2nd}.step1`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna2nd}.step2`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna2nd}.step3`)}</Text>
                </LocalLi>
                <LocalLi>
                  <Text>{t(`${myna2nd}.step4`)}</Text>
                </LocalLi>
              </LocalOl>
            </StepTextContainer>
            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t("common.launch")}
            </LocalButton>
          </ButtonContainer>
        </>
      );
    

    //"Processing" - 確認中...
    //(A temporary screen to show until the submission request is completed)
    case ContentType.Processing:
      return (
        <VerifyingResultsContainer>
          <Column style={{ width: 'auto', gap: 20 }}>
            <Spinner />
            <Text>{t(`${processing}`)}</Text>          
          </Column>
        </VerifyingResultsContainer>
      )
  

    //"User authentication requested" - ユーザー認証リクエストを送信しました
    case ContentType.UserAuthenticationRequested:
      return (
        <>
          <NavContainer style={{ marginBottom: 30 }}>
            <SuccessIcon />
          </NavContainer>
          <LocalColumn>
            <HeadingText>{t(`${reqAuth}.title`)}</HeadingText>
            <MessageTextContainer>
              <Text>{t(`${reqAuth}.description1`)}</Text>
              <Text style={{ display: 'block' }}>
                <Trans i18nKey={`${reqAuth}.description2`}  />
              </Text>
            </MessageTextContainer>
            <InlineMessage variant="caution">
              {t(`${reqAuth}.youCanStartEntering`)}
            </InlineMessage>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t("common.getStarted")}
            </LocalButton>
          </ButtonContainer>
        </>
      );
  }
};


const OnboardingPage: FunctionComponent<OnboardingPageProps> = () => {
  const { state, dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [contentType, setContentType] = useState<ContentType>(
    isUserRegistrationApplicationDenied(state.userRegistrationAppStatus)
      ? ContentType.UserAuthenticationRequest
      : ContentType.Welcome
  );
  const nextActionKey = "next_action";
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    contentRef.current?.scrollIntoView({ block: "start" })
  };
  const getReturnUrlWithActionParam = (action: NextAction) => {
    return `/onboarding?${nextActionKey}=${action}`;
  };
  const onClickNext = (() => {
    switch (contentType) {
      case ContentType.Welcome:
        return () => {
          setContentType(ContentType.TellUsAboutYourself1);
        };
      case ContentType.UserAuthenticationRequest:
        return () => {
          setContentType(ContentType.TellUsAboutYourself1);
        };
      case ContentType.TellUsAboutYourself1:
        return () => {
          setContentType(ContentType.TellUsAboutYourself2);
        };
      case ContentType.TellUsAboutYourself2:
        return () => {
          setContentType(ContentType.DownloadMynaApp);
        };
      case ContentType.DownloadMynaApp:
        return () => {
          setContentType(ContentType.ReadMynaCard1stTime);
        };
      case ContentType.ReadMynaCard1stTime:
        return async () => {
          dispatcher.startLoading();
          try {
            const userRegistrationApplication = await getLatestUserRegistrationApplication();
            const userRegistrationSubmission = await createUserRegistrationSubmission(userRegistrationApplication.id);
            const result = await fetchFirstMynaURLToSubmitUserRegistrationApplication(
              userRegistrationSubmission.id,
              getReturnUrlWithActionParam(NextAction.Request2ndMynaURLToRead)
            );
            window.location.href = result.myna_cert_id_url;
          } catch (e) {
            dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
          } finally {
            dispatcher.stopLoading();
          }
        };
      case ContentType.ReadMynaCard2ndTime:
        return async () => {
          dispatcher.startLoading();
          try {
            const userRegistrationApplication = await getLatestUserRegistrationApplication();
            const userRegistrationSubmissionId = userRegistrationApplication.last_unsubmitted_riyousha_touroku_submission!.id;
            const result = await fetchSecondMynaURLToSubmitUserRegistrationApplication(
              userRegistrationSubmissionId,
              getReturnUrlWithActionParam(NextAction.RequestToSubmitUserAuth)
            );
            window.location.href = result.myna_cert_signature_url;
          } catch (e) {
            dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
            setContentType(ContentType.ReadMynaCard1stTime);
          } finally {
            dispatcher.stopLoading();
          }
        };
      case ContentType.UserAuthenticationRequested:
        return () => {
          dispatcher.setUserRegistrationAppStatus(UserRegistrationApplicationStatus.Applying);
          navigate("/");
        };
      default:
        return () => {}
    }
  })();

  const onClickBack = (() => {
    switch (contentType) {
      case ContentType.TellUsAboutYourself1:
        return () => {
          setContentType(isUserRegistrationApplicationDenied(state.userRegistrationAppStatus)
            ? ContentType.UserAuthenticationRequested
            : ContentType.Welcome
          );
        };
      case ContentType.TellUsAboutYourself2:
        return () => {
          setContentType(ContentType.TellUsAboutYourself1);
        };
      case ContentType.DownloadMynaApp:
        return () => {
          setContentType(ContentType.TellUsAboutYourself2);
        };
      case ContentType.ReadMynaCard1stTime:
        return () => {
          setContentType(ContentType.DownloadMynaApp);
        };
      default:
        return () => {};
    }
  })();

  useEffect(() => {
    scrollToTop();
  }, [contentType])

  useEffect(() => {
    const params = extractURLParameters();

    if (!params || !(nextActionKey in params)) return;

    const nextAction = params[nextActionKey];

    switch (nextAction) {
      case NextAction.Request2ndMynaURLToRead:
        setContentType(ContentType.ReadMynaCard2ndTime);
        return;

      case NextAction.RequestToSubmitUserAuth:
        //Show this temporary screen until the submission is complete
        setContentType(ContentType.Processing);

        (async () => {
          try {
            const userRegistrationApplication = await getLatestUserRegistrationApplication();
            await submitUserRegistrationApplication(
              userRegistrationApplication.last_unsubmitted_riyousha_touroku_submission!.id
            );
            setContentType(ContentType.UserAuthenticationRequested);
          } catch (e) {
            dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
            setContentType(ContentType.ReadMynaCard1stTime);
          } 
        })();
        break;
    }
  }, []);

  return (
    <Modal>
      <ContentContainer>
        <Content ref={contentRef}>
          <MessageContent
            contentType={contentType}
            onEvent={{
              clickNext: onClickNext,
              clickBack: onClickBack,
            }}
          />
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default OnboardingPage;