import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Text from '../text/Text';

interface DisplayLabelProps extends HTMLAttributes<HTMLDivElement> {
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  
  > *:first-child {
    flex: 1;
    margin-right: 6px;
  }
`;

const DisplayLabel: FunctionComponent<DisplayLabelProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            <Text color="#999999">{children}</Text>
        </Container>
    );
};

export default DisplayLabel;