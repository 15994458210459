import { VisaApplicationStatus } from "../types/visa/applicationStatus";
import { VisaApplication, VisaSubmission } from "../types/visa/applicationData";
import { MynaFirstCardReadApiResponse, MynaSecondCardReadApiResponse } from "../types/visa/responses";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const createVisaApplication = async () => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `visa_applications`,
      data: {
        app_status: VisaApplicationStatus.Working,
        verify_to_submit: false
      }
  });

  if (hasErrorStatus(response)) 
    throwRequestError('createVisaApplication', response.status);

  return response.data as VisaApplication;
};

export const getVisaApplication = async (visaApplicationId: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `visa_applications/${visaApplicationId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('getVisaApplication', response.status);

  return response.data as VisaApplication;
};

export const updateVisaApplication = async (visaApplicationId: number | string, data: Partial<VisaApplication>) => {
  const response = await axiosInstance.request({
      method: 'PATCH',
      url: `visa_applications/${visaApplicationId}`,
      data
  });

  if (hasErrorStatus(response))
    throwRequestError('updateVisaApplication', response.status);

  return response.data as VisaApplication;
}

export const getVisaApplications = async () => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `visa_applications`
  });

  if (hasErrorStatus(response))
    throwRequestError('getVisaApplications', response.status);  

  return response.data as VisaApplication[];
};

export const deleteVisaApplication = async (visaApplicationId: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `visa_applications/${visaApplicationId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('deleteVisaApplication', response.status); 

  return response.data as VisaApplication;
};


/**************************************************************************/


//This API MUST be called before proceeding to the submission of the visa application
export const processUserDataBeforeSubmission = async (
  visaApplicationId: number | string
) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'visa_submissions/',
      timeout: 80000,
      data: {
        visa_application_id: visaApplicationId,
      }
  });

  if (hasErrorStatus(response))
    throwRequestError("processUserDataBeforeSubmission", response.status);

  return response.data as VisaSubmission;
};


//This API gets the URL to Myna App (1st time)
export const fetchFirstMynaURLToSubmitVisaApplication = async (
  visaSubmissionId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'visa_submissions/submit_1',
      data: {
        visa_submission_id: visaSubmissionId,
        visa_comeback_path: 
          comebackPathAfterCardRead.startsWith('/')
            ? comebackPathAfterCardRead
            : `/${comebackPathAfterCardRead}`
      }
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchFirstMynaURLToSubmitVisaApplication", response.status);

  return response.data as MynaFirstCardReadApiResponse;
};

//PRE-REQUISITE:
//(1) Before this API is called, the first card reading API must have already been called and the user already has read his/her card
//(2) This API should be called within 5 miniutes after the first card reading API is called; or
//    the user will fail to read the second card reading
export const fetchSecondMynaURLToSubmitVisaApplication = async (
  visaSubmissionId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'visa_submissions/submit_2',
      data: {
        visa_submission_id: visaSubmissionId,
        visa_comeback_path:                      
          comebackPathAfterCardRead.startsWith('/') 
            ? comebackPathAfterCardRead 
            : `/${comebackPathAfterCardRead}`
      }
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchSecondMynaURLToSubmitVisaApplication", response.status);

  return response.data as MynaSecondCardReadApiResponse;
};


//PRE-REQUISITES:
//(1) Visa form data and attachments must be already saved at backend
//(2) The user's Myna card has already been read twice
//(3) This API must be called within 5 minutes after the 2nd card reading; or the user will fail to submit
export const submitVisaApplication = async (visaSubmissionId: number | string) => {
  const response = await axiosInstance.request({
    method: "POST",
    url: "visa_submissions/submit_3",
    data: {
      visa_submission_id: visaSubmissionId
    }
  });

  if (hasErrorStatus(response))
    throwRequestError("submitVisaApplication", response.status);

  return { status: "ok" };
};
 

/**************************************************************************/

//The following APIs are used to get the current status of the visa application (e.g. Approved).
//Implementation is tricky because internally we have to call 4 APIs to get the result

//This API must be called before `fetchCurrentApplicationStatus` and the user must read his/her card
export const fetchMynaURLToConfirmVisaApplicationStatus = async (
  visaApplicationId: number | string,
  comebackPathAfterCardRead: string
) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'visa_submissions/show_1',
      data: {
        visa_application_id: visaApplicationId,
        visa_comeback_path: comebackPathAfterCardRead.startsWith('/') 
          ? comebackPathAfterCardRead 
          : `/${comebackPathAfterCardRead}`
      }
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchMynaURLToConfirmApplicationStatus", response.status);

  return response.data as MynaFirstCardReadApiResponse;
};

//This API must be called after `fetchMynaURLToConfirmVisaApplicationStatus` and the user must have read his/her card
//Note: if 5 minutes have passed since the first card reading, the user will fail to get the result
export const fetchCurrentVisaApplicationStatus = async (
  visaSubmissionId: number | string,
) => {
  let response = await axiosInstance.request({
    method: 'POST',
    url: 'visa_submissions/show_2'
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchCurrentVisaApplicationStatus : show_2", response.status);


  response = await axiosInstance.request({
    method: 'POST',
    url: 'visa_submissions/get_detail',
    data: { visa_submission_id: visaSubmissionId}
  });

  if (hasErrorStatus(response))
    throwRequestError("fetchCurrentVisaApplicationStatus : get_detail", response.status);

  return response.data as VisaSubmission;
};


//The original methods also can be used to fetch the latest visa messages
//but here, assigned alias to avoid confusion
export const fetchMynaUrlToFetchLatestVisaMessages = fetchMynaURLToConfirmVisaApplicationStatus;
export const fetchLatestVisaMessages = fetchCurrentVisaApplicationStatus;