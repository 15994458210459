import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import RadioItem from '../form/RadioItem';

export type RadioOption = {
    disabled?: boolean;
    invalid?: boolean;
    label: string;
    value: any;
};

interface RadioFieldProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    required?: boolean;
    label?: string;
    options?: RadioOption[];
    value?: any;
    error?: string;
    restriction?: string;
    note?: string;
    onValueChange?: (value: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const RadioField: FunctionComponent<RadioFieldProps> = ({
    disabled,
    required,
    label,
    options,
    value,
    error,
    restriction,
    note,
    onValueChange,
}) => {
    return (
        <Container>
            {label && (
                <Label required={required}>
                    {label}
                </Label>
            )}
            <Group>
                {options?.map((option) => (
                    <RadioItem
                        key={option.value}
                        disabled={disabled || !!option.disabled}
                        invalid={!!error || !!option.invalid}
                        value={option.value}
                        checked={value === option.value}
                        onValueChange={onValueChange}
                    >
                        {option.label}
                    </RadioItem>
                ))}
            </Group>
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {restriction && (
                <Restriction content={restriction} />
            )}
            {note && (
                <InlineMessage variant='info' size="small">
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default RadioField;