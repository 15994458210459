import { FunctionComponent, useEffect, useReducer, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import LoadingModal from './components/modal/LoadingModal';
import { INITIAL_STATE, ReducerContext, reducer } from './reducer';
import NavBar from './NavBar';
import Snackbar from './components/display/Snackbar';
import SiteRoutes from './routes';
import Text from './components/text/Text';
import ACTIONS from './actions';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FAF6F4;
`;

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
    -webkit-text-size-adjust: 100%;
  }

  * {
    box-sizing: border-box;
  }
`;

const App: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [timeoutId, setTimeoutId] = useState<number>();

  useEffect(() => {
    if (state.snackbar.isShowing) {
      if (timeoutId)
        clearTimeout(timeoutId);

      const newTimeoutId = window.setTimeout(() => {
        dispatch({ type: ACTIONS.CLEAR_SNACKBAR });
      }, 5000);

      setTimeoutId(newTimeoutId);
    }
  }, [state.snackbar.isShowing]);

  return (
    <ReducerContext.Provider value={{ state, dispatch }}>
      <Container>
          <GlobalStyle />
          <NavBar />
          <SiteRoutes />
          {state.isLoading && (
            <LoadingModal/>
          )}
          {state.snackbar.isShowing &&
            <Snackbar type={state.snackbar.type}>
              <Text color="#FFFFFF">
                {state.snackbar.message}
              </Text>
            </Snackbar>
          }
      </Container>
    </ReducerContext.Provider>
  );
};

export default App;
