//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1263905895
export enum Gender {
  Male = '1',
  Female = '2'
}


//Ref: https://drive.google.com/file/d/19MS6rp7M2_Xk68g8TwkoCrKMVQKLVvij/view
export enum TermsOfUseAgreement {
  Disagree = '0',
  Agree = '1',
}

/* NOTE ON WCFDS010Dto:selUserKbn (利用申請者の区分 / User category)
   First, The value of this field is NOT equal with status of residence codes.
   Second, the actual possible values are really complicated,
   but we found that we had to use '4' if the user him/herself applies. */
export enum UserCategory {
  ForeignerSelf = '4'
}