import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_ja from "./ja.json";
import translation_en from "./en.json";
import translation_vi from "./vi.json";

const resources = {
    ja: {
      translation: translation_ja
    },
    en: {
      translation: translation_en
    },
    vi: {
      translation: translation_vi
    }
  };

  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    lng: localStorage.getItem('lng') || 'en', 
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
