export const DESIRED_PERIOD_OF_STAY_FOR_DEPENDENT = [
  {
      "label": "desiredPeriodOfStayForDependent.T440001000",
      "value": "T440001000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440002000",
      "value": "T440002000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440003000",
      "value": "T440003000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440004000",
      "value": "T440004000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440005000",
      "value": "T440005000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440006000",
      "value": "T440006000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440007000",
      "value": "T440007000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440008000",
      "value": "T440008000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440009000",
      "value": "T440009000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440010000",
      "value": "T440010000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440011000",
      "value": "T440011000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440100000",
      "value": "T440100000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440101000",
      "value": "T440101000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440102000",
      "value": "T440102000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440103000",
      "value": "T440103000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440104000",
      "value": "T440104000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440105000",
      "value": "T440105000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440106000",
      "value": "T440106000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440107000",
      "value": "T440107000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440108000",
      "value": "T440108000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440109000",
      "value": "T440109000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440110000",
      "value": "T440110000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440111000",
      "value": "T440111000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440200000",
      "value": "T440200000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440201000",
      "value": "T440201000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440202000",
      "value": "T440202000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440203000",
      "value": "T440203000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440204000",
      "value": "T440204000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440205000",
      "value": "T440205000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440206000",
      "value": "T440206000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440207000",
      "value": "T440207000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440208000",
      "value": "T440208000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440209000",
      "value": "T440209000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440210000",
      "value": "T440210000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440211000",
      "value": "T440211000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440300000",
      "value": "T440300000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440301000",
      "value": "T440301000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440302000",
      "value": "T440302000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440303000",
      "value": "T440303000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440304000",
      "value": "T440304000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440305000",
      "value": "T440305000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440306000",
      "value": "T440306000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440307000",
      "value": "T440307000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440308000",
      "value": "T440308000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440309000",
      "value": "T440309000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440310000",
      "value": "T440310000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440311000",
      "value": "T440311000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440400000",
      "value": "T440400000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440401000",
      "value": "T440401000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440402000",
      "value": "T440402000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440403000",
      "value": "T440403000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440404000",
      "value": "T440404000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440405000",
      "value": "T440405000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440406000",
      "value": "T440406000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440407000",
      "value": "T440407000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440408000",
      "value": "T440408000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440409000",
      "value": "T440409000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440410000",
      "value": "T440410000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440411000",
      "value": "T440411000"
  },
  {
      "label": "desiredPeriodOfStayForDependent.T440500000",
      "value": "T440500000"
  },
]