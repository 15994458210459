import { FunctionComponent, useState } from "react";
import Modal from "../../components/modal/Modal";
import styled from "styled-components";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import BackButton from "../../components/navigation/BackButton";
import { ReactComponent as CloseIcon } from "../../assets/icon-cross.svg";
import Button from "../../components/form/Button";
import Label from "../../components/form/Label";
import TextInput from "../../components/form/TextInput";
import { useTranslation } from "react-i18next";
import { useDispatcher } from "../../hooks";
import { updateCurrentUser } from "../../apis/user";

interface RasIDPasswordModalProps {
  onClose: () => void;
  onBack: () => void;
  onSave: () => void;
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled(Column)`
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyContainer = styled(Column)`
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const LocalColumn = styled(Column)`
  gap: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 78px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RasIDPasswordModal: FunctionComponent<RasIDPasswordModalProps> = ({
  onClose,
  onBack,
  onSave,
}) => {
  const { dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const [rasID, setRasID] = useState("");
  const [rasPassword, setRasPassword] = useState("");
  const rasInfo = "modalRasInfo";

  const onClick = async () => {
    dispatcher.startLoading();
    try {
      await updateCurrentUser({
        ras_id: rasID,
        ras_password: rasPassword,
      });
      onSave();
    } catch {
      dispatcher.showSnackbar(t("snackbar.failedToSave"), "warning");
    } finally {
      dispatcher.stopLoading();
    }
  };

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <IconContainer>
            <BackButton onClick={onBack} />
            <CloseIcon onClick={onClose} />
          </IconContainer>
          <BodyContainer>
            <HeadingText>{t(`${rasInfo}.title`)}</HeadingText>
            <LocalColumn>
              <Text>{t(`${rasInfo}.description1`)}</Text>
              <Text color="#626161">{t(`${rasInfo}.description2`)}</Text>
            </LocalColumn>

            {/* RAS ID */}
            <LocalColumn>
              <Label>{t(`${rasInfo}.id`)}</Label>
              <TextInput value={rasID} onValueChange={setRasID} />
            </LocalColumn>

            {/* RAS Password */}
            <LocalColumn>
              <Label>{t(`${rasInfo}.password`)}</Label>
              <TextInput
                type="password"
                value={rasPassword}
                onValueChange={setRasPassword}
              />
            </LocalColumn>
          </BodyContainer>
          <ButtonContainer>
            <Button
              disabled={!!!rasID || !!!rasPassword}
              variant="primary"
              onClick={onClick}
            >
              {t("common.next")}
            </Button>
          </ButtonContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default RasIDPasswordModal;
