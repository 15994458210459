import { FunctionComponent } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/changeBySelf/data";
import {
  convertToFullAmount,
  formatDate,
  getLabelFromNationalityCode,
  getLabelsFromAddressCode,
  getTransKeyForCrimePunishmentStatus,
  getTransKeyForGender,
  parseName,
} from "../../../../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import PreviewItemList from "../../../PreviewItemList";
import {
  Indent,
  PreviewHeading,
  PreviewSubHeading,
} from "../../previewBodyCommonStyle";
import { CrimePunishmentStatus } from "../../../../../../types/visa/formCommonValues";

interface BasicInformationSectionProps {
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const BasicInformationSection: FunctionComponent<
  BasicInformationSectionProps
> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <section>
      <PreviewHeading>{t("basicInformationSection.title")}</PreviewHeading>
      <PreviewItemList
        items={[
          {
            label: t("basicInformationSection.familyName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").familyName,
          },
          {
            label: t("basicInformationSection.givenName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").givenName,
          },
          {
            label: t("basicInformationSection.middleName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").middleName,
          },
          {
            label: t("basicInformationSection.gender"),
            value: getTransKeyForGender(data["WCIBS010Dto:radSex"]) 
              ? t(`basicInformationSection.${getTransKeyForGender(data["WCIBS010Dto:radSex"])}`)
              : ""
          },
          {
            label: t("basicInformationSection.birthdate"),
            value: formatDate(
              data["WCIBS010Dto:selDateOfBirthYear"],
              data["WCIBS010Dto:selDateOfBirthMonth"],
              data["WCIBS010Dto:selDateOfBirthDay"]
            ),
          },
          {
            label: t("basicInformationSection.nationality"),
            value: t(
              `${
                getLabelFromNationalityCode(
                  data["WCIBS010Dto:selNationalityAndRegion"]
                ) ?? ""
              }`
            ),
          },
          {
            label: t("basicInformationSection.residenceInHomeCountry"),
            value: data["WCIBS010Dto:txtHomeCountryAddress"],
          },
        ]}
      />

      <Indent style={{ paddingTop: 10, paddingBottom: 10 }}>
        <PreviewSubHeading>{t("basicInformationSection.currentResidence")}</PreviewSubHeading>
        <PreviewItemList
          items={[
            {
              label: t("basicInformationSection.prefecture"),
              value: t(
                `${
                  getLabelsFromAddressCode(
                    data["WCIBS010Dto:hdnSearchedAddress"]
                  )?.prefecture ?? ""
                }`
              ),
            },
            {
              label: t("basicInformationSection.municipality"),
              value: t(
                `${
                  getLabelsFromAddressCode(
                    data["WCIBS010Dto:hdnSearchedAddress"]
                  )?.municipality ?? ""
                }`
              ),
            },
            {
              label: t("basicInformationSection.townStreetApartment"),
              value: data["WCIBS010Dto:txtDetailHomeAddress"],
            },
          ]}
        />
      </Indent>
      <PreviewItemList
        areOddRowsWhite={true}
        items={[
          {
            label: t("basicInformationSection.cellphoneNumber"),
            value: data["WCIBS010Dto:txtCelPhoneNum"],
          },
          {
            label: t("basicInformationSection.phoneNumber"),
            value: data["WCIBS010Dto:txtTelNum"],
          },
          {
            label: t("basicInformationSection.email"),
            value: data["WCIBS010Dto:txtMailAddress"],
          },
          {
            label: t("basicInformationSection.passportNumber"),
            value: data["WCIBS010Dto:txtPassportNum"],
          },
          {
            label: t("basicInformationSection.passportExpirationDate"),
            value: formatDate(
              data["WCIBS010Dto:selPassportExpirationYear"],
              data["WCIBS010Dto:selPassportExpirationMonth"],
              data["WCIBS010Dto:selPassportExpirationDay"]
            ),
          },
          {
            label: t("basicInformationSection.amountOfResidentTaxPaid"),
            value: convertToFullAmount(data["WCIBS010Dto:txtTaxPay"]),
          },
          {
            label: t("basicInformationSection.criminalRecordDescription"),
            value: getTransKeyForCrimePunishmentStatus(data["WCIBS010Dto:radHnziUm1"])
              ? t(`${getTransKeyForCrimePunishmentStatus(data["WCIBS010Dto:radHnziUm1"])}`)
              : ""
          },
          {
            label: t("basicInformationSection.criminalRecordDetails"),
            value: data["WCIBS010Dto:txtHnziNiyu"],
            skip: data["WCIBS010Dto:radHnziUm1"] === CrimePunishmentStatus.None,
          },
        ]}
      />
    </section>
  );
};

export default BasicInformationSection;
