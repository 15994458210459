
import { Language } from "../language";

//This value is independent from any other similar "status of residence" values 
//(e.g. Current status of residence code like "T44" or status of residence code like "04R1")
//This value is never sent to Myna nor the immigration office. Just used internally for categorization.
//In practice, we will need to convert this value to the corresponding status of residence code like one above.
export enum StatusOfResidenceOfUser {
  Dependent = 'dependent'
}

//Defined in the User table at backend but the data is never sent to frontend
//However, frontend needs to send the data to backend to update
export type ServerOnlyUserData = {
  ras_id: string;
  ras_password: string;
}

export type User = {
  id: number;
  created_at: string;
  updated_at: string;

  email: string;
  user_setting: UserSetting;
  status_of_residence: StatusOfResidenceOfUser;
  is_paid: boolean;

  //For payment with Stripe
  stripe_invoice_url: string;
}

export type UserSetting = {
  id: number;
  created_at: string;
  updated_at: string;

  language: Language;
}

export type UpdatableUserSetting = {
  language_id: number;
}