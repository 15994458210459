import { FunctionComponent, HTMLAttributes } from 'react';

type EmailAnchorProps = HTMLAttributes<HTMLAnchorElement> & {
    mailTo: string;
    noUnderline?: boolean;
};

const EmailAnchor: FunctionComponent<EmailAnchorProps> = ({
    mailTo,
    noUnderline,
    children,
    ...props
}) => {
    return (
        <a 
            href={`mailTo:${mailTo}`}
            style={{color: '#017698', textDecoration: noUnderline ? 'none' : 'underline' }}
            {...props}
        >
            {children}
        </a>
    );
};

export default EmailAnchor;