import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/changeBySelf/data";
import {
  formatDate,
  getLabelFromFamilyRelationCode,
  getLabelFromNationalityCode,
  getLengthOfZincSnzkList,
  getTransKeyForFamilyInJapanOrCohabitants,
  getTransKeyForLivingTogetherWithThisFamilyOrCohabitant,
} from "../../../../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import PreviewItemList from "../../../PreviewItemList";
import { PreviewItem } from "../../../../../../types/modal/preview";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
} from "../../../../../../utils/visaApplicationHelper";
import {
  Indent,
  PreviewHeading,
  PreviewSubHeading,
} from "../../previewBodyCommonStyle";
import { generateRandomString } from "../../../../../../utils/utils";

interface FamilyCoresidentsSectionProps {
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const FamilyCoresidentsSection: FunctionComponent<
  FamilyCoresidentsSectionProps
> = ({ data }) => {
  const { t } = useTranslation();
  const length = getLengthOfZincSnzkList(data);
  const [previewItemsList, setPreviewItemsList] = useState<PreviewItem[][]>([]);

  useEffect(() => {
    const itemsList: PreviewItem[][] = [];

    for (let i = 1; i <= length; i++) {
      const items: PreviewItem[] = [];

      if (isExtensionBySelfData(data)) {
        items.push(
          {
            label: t("familyCoresidentsSection.relationship"),
            value: t(
              `${
                getLabelFromFamilyRelationCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("familyCoresidentsSection.name"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("familyCoresidentsSection.birthdate"),
            value: formatDate(
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ExtensionBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ExtensionBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ExtensionBySelfData
              ] as string
            ),
          },
          {
            label: t("familyCoresidentsSection.nationality"),
            value: t(
              `${
                getLabelFromNationalityCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("familyCoresidentsSection.livigingTogether"),
            value: t(
              `${getTransKeyForLivingTogetherWithThisFamilyOrCohabitant(
                data[
                  `WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ExtensionBySelfData
                ] as string
              )}`
            ),
          },
          {
            label: t("familyCoresidentsSection.employmentOrSchool"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("familyCoresidentsSection.residenceCardNumber"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ExtensionBySelfData
            ] as string,
          }
        );
      }

      if (isChangeBySelfData(data)) {
        items.push(
          {
            label: t("familyCoresidentsSection.relationship"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].selZkgr` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("familyCoresidentsSection.name"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtName` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("familyCoresidentsSection.birthdate"),
            value: formatDate(
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthYear` as keyof ChangeBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthMonth` as keyof ChangeBySelfData
              ] as string,
              data[
                `WCIBS010Dto:zincSnzkList[${i}].selDateOfBirthDay` as keyof ChangeBySelfData
              ] as string
            ),
          },
          {
            label: t("familyCoresidentsSection.nationality"),
            value:
              t(
                `${getLabelFromNationalityCode(
                  data[
                    `WCIBS010Dto:zincSnzkList[${i}].selNationalityAndRegion` as keyof ChangeBySelfData
                  ] as string
                )}`
              ) ?? "",
          },
          {
            label: t("familyCoresidentsSection.livigingTogether"),
            value: t(
              `${getTransKeyForLivingTogetherWithThisFamilyOrCohabitant(
                data[
                  `WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ChangeBySelfData
                ] as string
              )}`
            ),
          },
          {
            label: t("familyCoresidentsSection.employmentOrSchool"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("familyCoresidentsSection.residenceCardNumber"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ChangeBySelfData
            ] as string,
          }
        );
      }

      itemsList.push(items);
    }

    setPreviewItemsList(itemsList);
  }, []);

  
  return (
    <section>
      <PreviewHeading>{t("familyCoresidentsSection.title")}</PreviewHeading>
      <PreviewItemList
        items={[
          {
            label: t("familyCoresidentsSection.familyInJapan"),
            value: t(
              `${getTransKeyForFamilyInJapanOrCohabitants(
                data["WCIBS010Dto:radZincSnzkUm1"]
              )}`
            ),
          },
        ]}
      />
      {previewItemsList.length > 0 && (
        <Indent>
          {previewItemsList.map((items, i) => (
            <div key={generateRandomString()} style={{ marginTop: 20 }}>
              <PreviewSubHeading>{i + 1}</PreviewSubHeading>
              <PreviewItemList items={items} />
            </div>
          ))}
        </Indent>
      )}
    </section>
  );
};

export default FamilyCoresidentsSection;
