import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface CompoundFieldProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px;
  border-radius: 3px;
  background: #EDEDED;
`;

const Title = styled.div`
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const FieldGroup: FunctionComponent<CompoundFieldProps> = ({
    title,
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {title && (
                <Title>{title}</Title>
            )}
            {children}
        </Container>
    );
};

export default FieldGroup;