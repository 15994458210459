import { FunctionComponent } from "react";
import styled from "styled-components";
import Text from "../../text/Text";
import { PreviewAttachment } from "../../../types/modal/preview";
import { convertBytesToMB } from "../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "../../../utils/utils";

interface PreviewFileListProps {
  attachments: PreviewAttachment[];
}

const ListContainer = styled.div`
  width: 100%;
`;

const ListRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  min-height: 40px;
  gap: 10px;
  background-color: #ffffff;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

const LabelText = styled(Text)`
  color: #999999;
  display: block;
  width: 100%;
  text-align: left;
`;

const TextImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
`;

const PreviewFileList: FunctionComponent<PreviewFileListProps> = ({
  attachments,
}) => {
  const { t } = useTranslation();

  return (
    <ListContainer>
      {attachments
        .filter((v) => !!!v.skip)
        .map((attachment) => (
          <ListRow key={generateRandomString()}>
            <LabelText>{attachment.label}</LabelText>

            {attachment.files.length === 0 && (
              <Text>{t("attachmentsSection.noFilesAttached")}</Text>
            )}

            {attachment.files.map((file, j) => {
              return (
                <TextImageWrapper key={generateRandomString()}>
                  <Text color="#999999">{convertBytesToMB(file.size)} MB</Text>
                  <Image src={file.url} />
                </TextImageWrapper>
              );
            })}
          </ListRow>
        ))}
    </ListContainer>
  );
};

export default PreviewFileList;
