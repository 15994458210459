import { FunctionComponent } from "react";
import styled from "styled-components";
import Text from "../../text/Text";
import { PreviewItem } from "../../../types/modal/preview";
import DisplayLabel from "../../form/DisplayLabel";
import { generateRandomString } from "../../../utils/utils";

interface PreviewItemListProps {
  items: PreviewItem[];
  areOddRowsWhite?: boolean;
}

interface ItemListRowProps {
  areOddRowsWhite?: boolean;
}

const ListContainer = styled.div`
  width: 100%;
`;

const ListRow = styled.div<ItemListRowProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px 10px 20px;
  align-items: center;
  min-height: 40px;
  gap: 20px;
  background-color: ${({ areOddRowsWhite }) =>
    areOddRowsWhite ? "#F7F7F7" : "#ffffff"};

  &:nth-child(odd) {
    background-color: ${({ areOddRowsWhite }) =>
      areOddRowsWhite ? "#ffffff" : "#F7F7F7"};
  }
`;

const LocalDisplayLabel = styled(DisplayLabel)`
  word-break: break-all;
  color: #999999;
`;

const LocalText = styled(Text)`
  word-break: break-all;
`;

const PreviewItemList: FunctionComponent<PreviewItemListProps> = ({
  items,
  areOddRowsWhite,
}) => {
  return (
    <ListContainer>
      {items
        .filter((v) => !!!v.skip)
        .map((item) => (
          <ListRow
            key={generateRandomString()}
            areOddRowsWhite={areOddRowsWhite}
          >
            <LocalDisplayLabel>{item.label}</LocalDisplayLabel>
            <LocalText>{item.value ?? ""}</LocalText>
          </ListRow>
        ))}
    </ListContainer>
  );
};

export default PreviewItemList;
