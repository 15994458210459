import { FunctionComponent, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as InfomationIcon } from '../../assets/icon-info.svg';
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red.svg';

interface InlineMessageProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'info' | 'caution' | 'alert';
    size?: 'small' | 'base';
}

const Container = styled.div<InlineMessageProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: ${(props: InlineMessageProps) => props.size === 'small' ? '5px 10px' : '10px'};
  border-radius: 2px;
  box-sizing: border-box;
  color: #444444;
  background: ${(props: InlineMessageProps) => {
    switch (props.variant) {
      case 'info':
        return props.size  === 'small'? '#F7F7F7' : '#EEEEEE';
      case 'caution':
        return '#FFEDB2';
      case 'alert':
        return '#FFE0E0';
      default:
        return '#EEEEEE';
    }
  }};
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: ${(props: InlineMessageProps) => props.size === 'small' ? 12 : 14}px;
  line-height: ${(props: InlineMessageProps) => props.size === 'small' ? 17 : 20}px;
`;

const Icon = css`
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

const InfoIcon = styled(InfomationIcon)`
  ${Icon}
`;

const AlertIcon = styled(WarningIcon)`
  ${Icon}
`;

const InlineMessage: FunctionComponent<InlineMessageProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            { props.variant === 'alert' 
              ? <AlertIcon />
              : <InfoIcon />
            }
            {children}
        </Container>
    );
};

export default InlineMessage;