import { FunctionComponent } from "react";
import styled from "styled-components";
import Modal from "../Modal";
import { CONTENT_MAX_WIDTH } from "../../../constants/styles";
import Column from "../../layout/Column";
import Text from "../../text/Text";
import { VisaApplicationType } from "../../../types/visa/applicationType";
import { VisaApplication } from "../../../types/visa/applicationData";
import BackButton from "../../navigation/BackButton";
import Button from "../../form/Button";
import FamilyVisaPreviewBody from "./previewBodies/FamilyVisaPreviewBody";
import InlineMessage from "../../form/InlineMessage";
import { useTranslation } from "react-i18next";
import { getVisaApplication } from "../../../apis/visaApplication";
import { checkVisaApplicationType } from "../../../utils/visaApplicationHelper";

interface PreviewModalProps {
  visaApplication: VisaApplication;
  title: string;
  description?: string;
  warningText?: string;
  primaryButtonText?: string;
  onClickPrimaryButton: () => void;
  onClickBackButton: () => void;
}

const LocalBackButton = styled(BackButton)`
  position: fixed;
  box-shadow: 0px 2px 6px 0px #00000040;
`;

const Heading = styled(Text)`
  margin-top: 60px;
  font-weight: 500;
  font-size: 20px;
`;

const HeaderContainer = styled(Column)`
  padding: 20px;
  gap: 20px;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled(Column)`
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 50px 20px 20px 20px;
`;

const PreviewModal: FunctionComponent<PreviewModalProps> = ({
  visaApplication,
  title,
  description,
  warningText,
  onClickPrimaryButton,
  primaryButtonText,
  onClickBackButton,
}) => {
  const { t } = useTranslation();
  const visaApplicationType = checkVisaApplicationType(visaApplication);

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <HeaderContainer>
            <LocalBackButton onClick={onClickBackButton} />
            <Heading>{title}</Heading>
            {description && <Text>{description}</Text>}
            {warningText && (
              <InlineMessage variant="alert">{warningText}</InlineMessage>
            )}
          </HeaderContainer>

          {(() => {
            switch (visaApplicationType) {
              case VisaApplicationType.ExtendFamilyVisaBySelf:
                return (
                  <FamilyVisaPreviewBody
                    visaApplication={visaApplication}
                    visaApplicationType={visaApplicationType}
                  />
                );

              default:
                return null;
            }
          })()}

          <ButtonContainer>
            <Button onClick={onClickPrimaryButton}>
              {primaryButtonText ?? t("common.close")}
            </Button>
          </ButtonContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default PreviewModal;
