const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  TREAT_AS_LOGGED_IN: 'TREAT_AS_LOGGED_IN',
  TREAT_AS_LOGGED_OUT: 'TREAT_AS_LOGGED_OUT',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  CLEAR_SNACKBAR: 'CLEAR_SNACKBAR',
  SET_USER_REGISTRATION_APP_STATUS: 'SET_USER_REGISTRATION_APP_STATUS',
  START_SAVING: 'START_SAVING',
  END_SAVING: 'END_SAVING',
  START_UPLOADING: 'START_UPLOADING',
  END_UPLOADING: 'END_UPLOADING',
  COMPLETE_SETUP: 'COMPLETE_SETUP',
}

export default ACTIONS;