import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import DateSelect from "../form/DateSelect";
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';

interface DateSelectFieldProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    required?: boolean;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    value?: string;
    error?: string;
    restriction?: string;
    note?: string;
    onValueChange?: (value: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const DateSelectField: FunctionComponent<DateSelectFieldProps> = ({
    disabled,
    required,
    label,
    minDate,
    maxDate,
    value,
    error,
    restriction,
    note,
    onValueChange,
}) => {
    return (
        <Container>
            {label && (
                <Label required={required}>
                    {label}
                </Label>
            )}
            <DateSelect
                disabled={disabled}
                invalid={!!error}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onValueChange={onValueChange}
            />
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {restriction && (
                <Restriction content={restriction} />
            )}
            {note && (
                <InlineMessage variant='info' size="small">
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default DateSelectField;