import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type CategoryHeadingProps = HTMLAttributes<HTMLHeadingElement> & {
    children?: ReactNode;
};

export const Container = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 23px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif;
  color: #444444;
`;

const CategoryHeading: FunctionComponent<CategoryHeadingProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default CategoryHeading;