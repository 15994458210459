import { UpdatableUserSetting, UserSetting } from "../types/user/data";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const updateUserSetting = async (id: number | string, data: Partial<UpdatableUserSetting>) => {
  const response = await axiosInstance.request({
      method: 'PATCH',
      url: `user_settings/${id}`,
      data
  });
  
  if (hasErrorStatus(response))
      throwRequestError('updateUserSetting', response.status);

  return response.data as UserSetting;
}

