import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher } from "../../hooks";
import BackButton from "../../components/navigation/BackButton";
import { ReactComponent as SuccessIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { ReactComponent as RedCrossIcon } from "../../assets/icon-cross-red.svg";
import { ReactComponent as PendingIcon } from "../../assets/icon-pending.svg";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values";
import EmailAnchor from "../../components/text/EmailAnchor";
import { extractURLParameters } from "../../utils/utils";
import {
  fetchMynaURLToConfirmUserRegistrationStatus,
  fetchUserRegistrationStatus,
  getLatestUserRegistrationApplication,
} from "../../apis/userRegistration";
import { UserRegistrationApplicationStatus } from "../../types/userRegistration/applicationStatus";
import { fetchFakeUserRegistrationStatus } from "../../apis/mock";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/display/Spinner";

interface UserAuthenticationModalProps {
  onConfirmAuthStatus: (authStatus: UserRegistrationApplicationStatus) => void;
  onClose: () => void;
}

enum ContentType {
  CheckAuthenticationResult,
  Processing,
  UserAuthenticationApproved,
  UserAuthenticationDenied,
  UserAuthenticationPending,
}

export enum NextAction {
  RequestToConfirmAuthStatus = "hmpgrqsttcfrmathstts",
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled(Column)`
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocalColumn = styled(Column)`
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const ButtonContainer = styled.div`
  margin-top: 78px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 14px;
    color: #444444;
  }
`;

const SupplementaryTextContainer = styled.div``;

const ProcessingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 13px;
  color: #999999;
  line-height: 18px;
`;


//NOTE: This modal is very similar but not same as the one in /ApplicationFormPage
const UserAuthenticationModal: FunctionComponent<
  UserAuthenticationModalProps
> = ({ onConfirmAuthStatus, onClose }) => {
  const { dispatcher } = useDispatcher();
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.CheckAuthenticationResult
  );
  const nextActionKey = "next_action";
  const getReturnUrlWithActionParam = (action: NextAction) => {
    return `/?${nextActionKey}=${action}`;
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const checkResult = "modalCheckAuthResult.checkResult";
  const processing = "modalCheckAuthResult.processing";
  const authApproved = "modalCheckAuthResult.auth.approved";
  const authDenied = "modalCheckAuthResult.auth.denied";
  const authPending = "modalCheckAuthResult.auth.pending";

  const onClick = (() => {
    switch (contentType) {
      case ContentType.CheckAuthenticationResult:
        return async () => {
          dispatcher.startLoading();
          try {
            const returnURL = getReturnUrlWithActionParam(
              NextAction.RequestToConfirmAuthStatus
            );
            const userRegistrationApplication = await getLatestUserRegistrationApplication();
            const result = await fetchMynaURLToConfirmUserRegistrationStatus(
              userRegistrationApplication.last_submitted_riyousha_touroku_submission!.id,
              returnURL
            );
            window.location.href = result.myna_cert_id_url;
          } catch {
            dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
          } finally {
            dispatcher.stopLoading();
          }
        };

      case ContentType.UserAuthenticationApproved:
        return () => {
          onConfirmAuthStatus(UserRegistrationApplicationStatus.Approved);
          onClose();
        };

      case ContentType.UserAuthenticationDenied:
        return () => {
          onConfirmAuthStatus(UserRegistrationApplicationStatus.Denied);
          dispatcher.setUserRegistrationAppStatus(UserRegistrationApplicationStatus.Denied);
          navigate('/onboarding')
        };

      case ContentType.UserAuthenticationPending:
        return () => {
          onConfirmAuthStatus(UserRegistrationApplicationStatus.Applying);
          onClose();
        }

      default:
        return () => {};
    }
  })();

  useEffect(() => {
    const params = extractURLParameters();

    if (!params || !(nextActionKey in params)) return;

    const nextAction = params[nextActionKey];

    switch (nextAction) {
      case NextAction.RequestToConfirmAuthStatus:
        //Show this temporary screen until the submission is complete
        setContentType(ContentType.Processing);

        (async () => {
          try {
            const userRegistrationApplication = await getLatestUserRegistrationApplication();
            const submissionId = userRegistrationApplication.last_submitted_riyousha_touroku_submission!.id;
            
            //Uncomment/comment the following line to use mock API or real API
            const result = await fetchUserRegistrationStatus(submissionId);
            //const result = await fetchFakeUserRegistrationStatus(submissionId, UserRegistrationApplicationStatus.Denied);

            switch (result.app_status) {
              case UserRegistrationApplicationStatus.Approved:
                setContentType(ContentType.UserAuthenticationApproved);
                onConfirmAuthStatus(UserRegistrationApplicationStatus.Approved);
                break;
              case UserRegistrationApplicationStatus.Denied:
                setContentType(ContentType.UserAuthenticationDenied);
                onConfirmAuthStatus(UserRegistrationApplicationStatus.Denied);
                break;
              case UserRegistrationApplicationStatus.Applying:
                setContentType(ContentType.UserAuthenticationPending);
                onConfirmAuthStatus(UserRegistrationApplicationStatus.Applying);
                break;
            }

          } catch (e) {
            dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
            setContentType(ContentType.CheckAuthenticationResult);
          }
        })();
        break;
    }
  }, []);

  return (
    <Modal>
      <ContentContainer>
        <Content>
          {(() => {
            switch (contentType) {
              //"Please complete Authentication" - ユーザー認証を完了してください
              case ContentType.CheckAuthenticationResult:
                return (
                  <>
                    <IconContainer style={{ marginBottom: 20 }}>
                      <BackButton onClick={onClose} />
                    </IconContainer>
                    <LocalColumn>
                      <HeadingText>{t(`${checkResult}.title`)}</HeadingText>
                      <MessageTextContainer>
                        <Text>
                          {t(`${checkResult}.description`)}
                        </Text>
                      </MessageTextContainer>
                      <StepTextContainer>
                        <LocalOl>
                          <LocalLi>
                            <Text>{t(`${checkResult}.step1`)}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{t(`${checkResult}.step2`)}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{t(`${checkResult}.step3`)}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{t(`${checkResult}.step4`)}</Text>
                          </LocalLi>
                        </LocalOl>
                      </StepTextContainer>
                      <SupplementaryTextContainer>
                        <SmallText>
                          <Trans
                            i18nKey={"common.ifYouAreHavingTrouble"}
                            components={{
                              email: VISADAS_SUPPORT_EMAIL,
                              anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL}/>,
                            }}
                          />
                        </SmallText>
                      </SupplementaryTextContainer>
                    </LocalColumn>
                    <ButtonContainer>
                      <Button variant="primary" onClick={onClick}>
                        {t("common.launch")}
                      </Button>
                    </ButtonContainer>
                  </>
                );

              //"Processing" - 確認中
              case ContentType.Processing:
                return (
                  <>
                    <ProcessingContainer>
                      <Column style={{ width: "auto", gap: 20, alignItems: 'center' }}>
                        <Spinner />
                        <Text>{t(`${processing}`)}</Text>
                      </Column>
                    </ProcessingContainer>
                  </>
                );

              //"User Authentication Approved" - ユーザー認証が承認されました
              case ContentType.UserAuthenticationApproved:
                return (
                  <>
                    <IconContainer>
                      <SuccessIcon />
                    </IconContainer>
                    <LocalColumn>
                      <HeadingText>{t(`${authApproved}.title`)}</HeadingText>
                      <MessageTextContainer>
                        <Text>{t(`${authApproved}.description1`)}</Text>
                        <Text style={{ display: "block" }}>
                          <Trans
                            i18nKey={`${authApproved}.description2`}
                            components={{
                              span: <span style={{ fontWeight: 500 }} />,
                            }}
                          />
                        </Text>
                        <Text>{t(`${authApproved}.description3`)}</Text>
                      </MessageTextContainer>
                    </LocalColumn>
                    <ButtonContainer>
                      <Button variant="primary" onClick={onClick}>
                        {t("common.close")}
                      </Button>
                    </ButtonContainer>
                  </>
                );

              //"User authentication denied" 
              case ContentType.UserAuthenticationDenied:
                return (
                  <>
                    <IconContainer>
                      <RedCrossIcon />
                    </IconContainer>
                    <LocalColumn>
                      <HeadingText>{t(`${authDenied}.title`)}</HeadingText>
                      <MessageTextContainer>
                        <Text>{t(`${authDenied}.description1`)}</Text>
                        <Text style={{ fontWeight: 500 }}>
                          {t(`${authDenied}.description2`)}
                        </Text>
                      </MessageTextContainer>
                    </LocalColumn>
                    <ButtonContainer>
                      <Button variant="primary" onClick={onClick}>
                        {t("common.tryAgain")}
                      </Button>
                    </ButtonContainer>
                  </>
                );

              //"User authentication pending" 
              case ContentType.UserAuthenticationPending:
                return (
                  <>
                    <IconContainer>
                      <PendingIcon />
                    </IconContainer>
                    <LocalColumn>
                      <HeadingText>{t(`${authPending}.title`)}</HeadingText>
                      <MessageTextContainer>
                        <Text>{t(`${authPending}.description1`)}</Text>
                        <Text style={{ fontWeight: 500 }}>
                          {t(`${authPending}.description2`)}
                        </Text>
                      </MessageTextContainer>
                    </LocalColumn>
                    <ButtonContainer>
                      <Button variant="primary" onClick={onClick}>
                        {t("common.close")}
                      </Button>
                    </ButtonContainer>
                  </>
                );
            }
          })()}
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default UserAuthenticationModal;
