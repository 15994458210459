import { FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

import Select, { SelectOption } from './Select';
import { useTranslation } from 'react-i18next';

const MONTHS_WITH_31_DAYS = [1, 3, 5, 7, 8, 10, 12];

interface DateSelectProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    invalid?: boolean;
    minDate?: Date;
    maxDate?: Date;
    value?: string;
    onValueChange?: (value: string) => void;
}

const Container = styled.div<DateSelectProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const DateSelect: FunctionComponent<DateSelectProps> = ({
    disabled,
    invalid,
    minDate,
    maxDate,
    value,
    onValueChange,
    ...props
}) => {
    const { t } = useTranslation();
    const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
    const [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
    const [dayOptions, setDayOptions] = useState<SelectOption[]>([]);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);

    const getMaxDayOfMonth = (month: number) => month === 2
        ? (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28)
        : (MONTHS_WITH_31_DAYS.includes(month) ? 31 : 30);
    const extractMinYear = () => minDate?.getFullYear() ?? 1930;
    const extractMaxYear = () => maxDate?.getFullYear() ?? new Date().getFullYear() + 10;
    const extractMinMonth = () => (minDate?.getMonth() ?? 0) + 1;
    const extractMaxMonth = () => (maxDate?.getMonth() ?? 11) + 1;
    const extractMinDay = () => minDate?.getDate() ?? 1;
    const extractMaxDay = () => maxDate?.getDate() ?? getMaxDayOfMonth(month);

    const notifyValueChange = (year: number, month: number, day: number) => {
        if (year > 0 && month > 0 && day > 0) {
            const yearText = ('000' + year).slice(-4);
            const monthText = ('0' + month).slice(-2);
            const dayText = ('0' + day).slice(-2);
            onValueChange?.(`${yearText}-${monthText}-${dayText}`);
        } else {
            onValueChange?.('');
        }
    };

    const handleYearChange = (year: number) => {
        setYear(year);
        setMonth(0);
        setDay(0);
        notifyValueChange(year, 0, 0);
    };

    const handleMonthChange = (month: number) => {
        setMonth(month);
        setDay(0);
        notifyValueChange(year, month, 0);
    }

    const handleDayChange = (day: number) => {
        setDay(day);
        notifyValueChange(year, month, day);
    }

    useEffect(() => {
        const minYear = extractMinYear();
        const maxYear = extractMaxYear();
        const yearOptions = [];
        for (let year = minYear; year <= maxYear; year += 1) {
            yearOptions.push({
                label: `${year}`,
                value: year,
            });
        }
        setYearOptions(yearOptions);
    }, [minDate, maxDate]);

    useEffect(() => {
        const minYear = extractMinYear();
        const maxYear = extractMaxYear();
        const minMonth = (year === minYear ? extractMinMonth() : 1);
        const maxMonth = (year === maxYear ? extractMaxMonth() : 12);
        const monthOptions = [];
        for (let month = minMonth; month <= maxMonth; month += 1) {
            monthOptions.push({
                label: `${month}`,
                value: month,
            });
        }
        setMonthOptions(monthOptions);
    }, [minDate, maxDate, year]);

    useEffect(() => {
        const minYear = extractMinYear();
        const maxYear = extractMaxYear();
        const minMonth = (year === minYear ? extractMinMonth() : 1);
        const maxMonth = (year === maxYear ? extractMaxMonth() : 12);
        const minDay = (year === minYear && month === minMonth ? extractMinDay() : 1);
        const maxDay = (year === maxYear && month === maxMonth ? extractMaxDay() : getMaxDayOfMonth(month));
        const dayOptions = [];
        for (let day = minDay; day <= maxDay; day += 1) {
            dayOptions.push({
                label: `${day}`,
                value: day,
            });
        }
        setDayOptions(dayOptions);
    }, [minDate, maxDate, year, month])

    useEffect(() => {
        if (value) {
            const elements = value.split('-');
            setYear(Number(elements[0] ?? 0));
            setMonth(Number(elements[1] ?? 0));
            setDay(Number(elements[2] ?? 0));
        }
    }, [value]);

    return (
        <Container>
            <Select
                disabled={disabled}
                invalid={invalid && !year}
                placeholder={t("placeholder.year")}
                options={yearOptions}
                value={year}
                onValueChange={handleYearChange}
            />
            <Select
                disabled={disabled}
                invalid={invalid && !month}
                placeholder={t("placeholder.month")}
                options={monthOptions}
                value={month}
                onValueChange={handleMonthChange}
            />
            <Select
                disabled={disabled}
                invalid={invalid && !day}
                placeholder={t("placeholder.day")}
                options={dayOptions}
                value={day}
                onValueChange={handleDayChange}
            />
        </Container>
    );
};

export default DateSelect;