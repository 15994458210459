
import { ChangeEvent, FunctionComponent, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { ValidationFunction } from '../../types/validation';
import { ReactComponent as EyeIcon } from '../../assets/icon-eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../assets/icon-eye-slash.svg';
import Row from '../layout/Row';
import Column from '../layout/Column';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
    invalid?: boolean;
    onValueChange?: (value: string) => void;
    // DEPRECATED
    validators?: ValidationFunction[];
    error?: string;
    onTextChange?: (value: string) => void;
    onValidationFail?: (value: string) => void;
};

const Input = styled.input<TextInputProps>`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid ${(props: TextInputProps) => props.invalid ? '#E93232' : '#8A8E94'};
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  background: ${(props: TextInputProps) => props.invalid ? '#FFF0F0' : '#F5F9FF'};
  outline: none;
  
  &[type=number] {
    text-align: right;
  }

  &:disabled {
    border-color: #8A8E94;
    background: #D5D5D5;
  }
  
  &:focus {
    border: 2px solid #1F82FF;
    background: #F5F9FF;
  }
  
  &::placeholder {
    color: #999999;
  }
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-ms-reveal {
    display: none
  }
`;

const InputContainer = styled(Row)`
  width: 100%;
  align-items: center;
`;

const PasswordIconContainer = styled(Column)`
  cursor: pointer;
  height: 40px;
  width: 40px;
  border: 1px solid #8A8E94;
  border-left: none;
  align-items: center;
  justify-content: center;
  background-color: #EDEDED;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const TextInput: FunctionComponent<TextInputProps> = ({
    onChange,
    onValueChange,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onValueChange?.(event.target.value);
    };

    if (props.type === 'password')
        return (
          <InputContainer>
              <Input
                  onChange={handleChange}
                  {...props}
                  type={showPassword
                    ? 'text' 
                    : 'password'}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
              />
              <PasswordIconContainer onClick={() => setShowPassword(!showPassword)}>
                  { showPassword 
                      ? <EyeSlashIcon /> 
                      : <EyeIcon /> }
              </PasswordIconContainer>
          </InputContainer>
        )

    return (
        <Input
            onChange={handleChange}
            {...props}
        />
    );
};

export default TextInput;