export enum VisaAttachmentKind {
  Application = 'application',
  Photo = 'photo',
  Passport = 'passport',
  ResidenceCard = 'residence_card',
  //The following item name says "Supporter" but the string says "dependent". This difference is intentional.
  //Backend uses the official translation and it uses the word "dependent", actually meaning "supporter". 
  //Most likely, it is a mistranslation
  DocsProvingRelationshipBtwApplicantAndSupporter = 'documents_proving_the_relationship_between_the_applicant_and_the_dependent',
  SupporterResidenceCardOrPassport = 'supporter_residence_card_or_passport',
  DocsCertifyingOccupationAndIncomeOfDependent = 'documents_certifying_occupation_and_income_of_dependent',
  Other = 'other'
}

export type VisaAttachment = {
  id: number;
  created_at: string;
  updated_at: string;

  visa_application_id: number;
  kind: VisaAttachmentKind;
  url: string;
  size: number;
}