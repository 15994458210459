import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import {
  getLabelFromDesiredPeriodOfStayForDependent,
  getLabelFromOfficeCode,
  getTransKeyForDesiredPeriodOfStay,
  getTransKeyForHowToReceiveNewResidenceCard,
} from "../../../../../../utils/visaFormDataHelper";
import { useTranslation } from "react-i18next";
import PreviewItemList from "../../../PreviewItemList";
import { PreviewHeading } from "../../previewBodyCommonStyle";
import { HowToReceiveNewResidenceCard } from "../../../../../../types/visa/formCommonValues";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";

interface DetailsOfExtensionSectionProps {
  visaApplicationType: VisaApplicationType;
  data: ExtensionBySelfData;
}

//As the name implies, this section only handles ExtensionBySelfData, not ChangeBySelfData
export const DetailsOfExtensionSection: FunctionComponent<
  DetailsOfExtensionSectionProps
> = ({ visaApplicationType, data }) => {
  const { t } = useTranslation();
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");

  useEffect(() => {
    if (data["WCIBS010Dto:hdnSearchedAddress"]) {
      setPrefecture(data["WCIBS010Dto:hdnSearchedAddress"].slice(0, 2) ?? "");
      setMunicipality(data["WCIBS010Dto:hdnSearchedAddress"].slice(2) ?? "");
    }
  }, []);

  return (
    <section>
      <PreviewHeading>{t("detailsOfExtensionSection.title")}</PreviewHeading>
      <PreviewItemList
        items={[
          {
            label: t("detailsOfExtensionSection.desiredPeriodOfStay"),
            value: t(
              `${getTransKeyForDesiredPeriodOfStay(
                data["WCIBS010Dto:selNewZiryPeriod"]
              )}`
            ),
          },
          {
            label: t("detailsOfExtensionSection.reasonOfExtention"),
            value: data["WCIBS010Dto:txtKusnReason"],
          },
          {
            label: t("detailsOfExtensionSection.recievingNewResidenceCard"),
            value: getTransKeyForHowToReceiveNewResidenceCard(data["WCIBS010Dto:radZiryCardJryHuhu"])
              ? t(`detailsOfExtensionSection.${
                  getTransKeyForHowToReceiveNewResidenceCard(data["WCIBS010Dto:radZiryCardJryHuhu"])
                }`)
              : ""
          },
          {
            label: t("detailsOfExtensionSection.pickUpOffice"),
            value: t(
              `${getLabelFromOfficeCode(
                data["WCIBS010Dto:selJryuKnsh"],
                prefecture,
                municipality
              )}`
            ),
            skip:
              data["WCIBS010Dto:radZiryCardJryHuhu"] ===
              HowToReceiveNewResidenceCard.Mail,
          },
        ]}
      />
    </section>
  );
};

export default DetailsOfExtensionSection;
