import { Language } from "../types/language";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const getLanguages = async () => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `languages`
  });

  if (hasErrorStatus(response))
    throwRequestError('getLanguages', response.status);  

  return response.data as Language[];
};
