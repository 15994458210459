import { ChangeBySelfData } from "../types/changeBySelf/data";
import { ExtensionBySelfData } from "../types/extensionBySelf/data";
import { StatusOfResidenceCode as StatusOfResidenceCodeForExtensionBySelf } from "../types/extensionBySelf/specificValues";
import { CSVSourceKind, VisaApplication, VisaMessage, VisaSubmission } from "../types/visa/applicationData";
import { VisaApplicationStatus } from "../types/visa/applicationStatus";
import { VisaApplicationType } from "../types/visa/applicationType";
import { ApplicationTypeTransKey, VisaTransKey } from "../types/visa/transKeys";

const isThisVisaApplication = (val: unknown): val is VisaApplication => {
  return typeof val === 'object' && val !== null && val.hasOwnProperty('csv_source_kind');
}

export const checkVisaApplicationType = (visaApplication: VisaApplication): VisaApplicationType | undefined => {
  switch (visaApplication.csv_source_kind) {
    case CSVSourceKind.ExtensionBySelf:
      const statusOfResidenceCode = visaApplication.koushin_honnin["selZirySkk"];

      switch (statusOfResidenceCode) {
        case StatusOfResidenceCodeForExtensionBySelf.Family:
          return VisaApplicationType.ExtendFamilyVisaBySelf;
      }
      break;
  }
}

export const isThisVisaExtension = (val: VisaApplication | VisaApplicationType): boolean => {
  if (isThisVisaApplication(val)) {
    return checkVisaApplicationType(val) === VisaApplicationType.ExtendFamilyVisaBySelf;
  } else {
    return val === VisaApplicationType.ExtendFamilyVisaBySelf;
  }
}

export const isThisVisaChange = (val: VisaApplication | VisaApplicationType): boolean => {
  if (isThisVisaApplication(val)) {
    //Not supporterd yet
    //return checkVisaApplicationType(val) === VisaApplicationType.ChangeFamilyVisaBySelf;
    return false;
  } else {
    //Not supporterd yet
    //return val === VisaApplicationType.ChangeFamilyVisaBySelf;
    return false;
  }
}

export const isExtensionBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ExtensionBySelfData => {
  //Checking "Reason for extension" property
  return data.hasOwnProperty('WCIBS010Dto:txtKusnReason');
}

export const isChangeBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ChangeBySelfData => {
  //Checking "Reason for change" property
  return data.hasOwnProperty('WCICS020Dto:txtKusnReason');
}

//Try to find the corresponding translation keys based on the visa application 
//e.g.
//  VisaApplicationType.ExtendFamilyVisaBySelf => { visa: "dependent", type: "extension" } 
export const getVisaInfoTransKeys = (VisaApplication: VisaApplication): Record<"visa" | "type", string> => {
  const visaApplicationType = checkVisaApplicationType(VisaApplication);
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendFamilyVisaBySelf:
      return {
        visa: VisaTransKey.Family,
        type: ApplicationTypeTransKey.Extension,
      };
    default:
      return {
        visa: "",
        type: "",
      };
  }
}

export const getVisaApplicationStatus = (visaApplication: VisaApplication): VisaApplicationStatus => {
  const lastSubmission = visaApplication.last_submitted_visa_submission;

  if (!lastSubmission)
    return VisaApplicationStatus.Working;

  return lastSubmission.app_status;
}

export const getVisaMessages = (val: VisaApplication | VisaSubmission): VisaMessage[] => {
  if (isThisVisaApplication(val)) {
    const lastSubmission = val.last_submitted_visa_submission;
  
    if (!lastSubmission)
      return [];
  
    return lastSubmission.visa_messages;
  }
  else {
    return val.visa_messages;
  }
}

export const isThisVisaApplicationSubmitted = (visaApplication: VisaApplication): boolean => {
  return visaApplication.last_submitted_visa_submission !== null;
}
