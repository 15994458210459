import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Page from "../../components/layout/Page";
import PageHeading from "../../components/text/PageHeading";
import { signUp } from "../../apis/auth";
import { useDispatcher, useValidation } from "../../hooks";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import TextField from "../../components/compound/TextField";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import Button from "../../components/form/Button";

interface SignupPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const ButtonContainer = styled.div`
  padding-top: 30px;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const SignupPage: FunctionComponent<SignupPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const validation = useValidation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const minPasswordLength = 8;
  const checkIfPaswordsMatch = () => {
    if (confirmPassword !== password) {
      setConfirmPasswordError(t("signupPage.passwordMismatchError"));
    } else {
      setConfirmPasswordError("");
    }
  }

  const handlePasswordBlur = () => {
    if (password.length < minPasswordLength) {
      setPasswordError(t("signupPage.passwordTooShortError"));
      return;
    } 

    if (confirmPassword.length >= minPasswordLength) 
      checkIfPaswordsMatch();
  };

  const handleConfirmPasswordBlur = () => {
    checkIfPaswordsMatch();
  };

  const onCreate = async () => {
    dispatcher.startLoading();
    try {
      await signUp(email, password);
      dispatcher.treatAsLoggedIn();
      navigate("/setup");
    } catch (e) {
      dispatcher.showSnackbar(t("snackbar.failedToProceed"), "warning");
    } finally {
      dispatcher.stopLoading();
    }
  };

  return (
    <LocalPage>
      <PageHeading>{t("signupPage.createAccount")}</PageHeading>
      <Column style={{ gap: 10 }}>
        <Text>{t("signupPage.description1")}</Text>
        <Text>{t("signupPage.description2")}</Text>
      </Column>

      <TextField
        label={t("signupPage.email")}
        value={email}
        error={emailError}
        validators={[validation.isEmailAddress]}
        onErrorChange={setEmailError}
        placeholder="visadas@gmail.com"
        onValueChange={setEmail}
      />

      <TextField
        type="password"
        label={t("signupPage.password")}
        value={password}
        error={passwordError}
        onValueChange={setPassword}
        onErrorChange={setPasswordError}
        onBlur={handlePasswordBlur}
        minLength={8}
        restriction={t("signupPage.mustBeAtLeast8Letters")}
      />

      <TextField
        type="password"
        label={t("signupPage.confirmPassword")}
        value={confirmPassword}
        error={confirmPasswordError}
        onValueChange={setConfirmPassword}
        onErrorChange={setConfirmPasswordError}
        onBlur={handleConfirmPasswordBlur}
        minLength={8}
        restriction={t("signupPage.passwordMustMatch")}
      />

      <ButtonContainer>
        <Button
          variant="primary"
          disabled={!email || !password || password !== confirmPassword}
          onClick={onCreate}
        >
          {t("signupPage.create")}
        </Button>
      </ButtonContainer>
    </LocalPage>
  );
};

export default SignupPage;
