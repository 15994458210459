import { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import UploadButton from '../form/UploadButton';
import { VisaAttachment, VisaAttachmentKind } from '../../types/visa/attachment';
import Column from '../layout/Column';
import Text from '../text/Text';
import Row from '../layout/Row';
import { ReactComponent as DeleteButton } from '../../assets/icon-cross.svg';
import { convertBytesToMB, extractVisaAttachments } from '../../utils/visaFormDataHelper';
import { useDispatcher } from '../../hooks';
import Spinner from '../display/Spinner';
import { useTranslation } from 'react-i18next';
import { generateRandomString } from '../../utils/utils';

interface UploadFieldProps {
    required?: boolean;
    label?: string;
    note?: ReactNode | string;
    additionalButton?: ReactNode,
    uploadButtonText?: string;
    visaAttachments?: VisaAttachment [];
    visaAttachmentKind?: VisaAttachmentKind;
    onAttachmentUpload?: (file: File, kind: VisaAttachmentKind, uploaderId?: string) => void;
    onAttachmentDelete?: (visaAttachmentId: number) => void;
    enableRequiredAlert?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const PreviewContainer = styled(Column)`
  align-items: center;
  background-color: #F7F7F7;
  padding: 10px 10px 20px 10px;
  gap: 10px;
`;

const UploadingMessageContainer = styled(Row)`
  background-color: #F2F2F2;
  padding: 10px;
  gap: 10px;
  justify-content: center;
`;

const FileSizeDeleteBtnContainer = styled(Row)`
  justify-content: space-between;
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
`;

const AlertMessage = styled(Text)`
  font-size: 13px;
  color: #E93232;
`;

const UploadField: FunctionComponent<UploadFieldProps> = ({
    required,
    label,
    note,
    additionalButton,
    uploadButtonText,
    visaAttachments,
    visaAttachmentKind,
    onAttachmentUpload,
    onAttachmentDelete,
    enableRequiredAlert
}) => {
    const sizeLimitInMB = 5;
    const { state, dispatcher } = useDispatcher();
    const { t } = useTranslation();
    const [ id ] = useState(generateRandomString());
    const targetAttachments = extractVisaAttachments(visaAttachments, visaAttachmentKind);
    const isFileBeingUploaded = 
        state.isUploading && state.uploaderIds.some(uploaderId => uploaderId === id);
    

    return (
        <Container>
            { (label || note) &&
                <Column style={{ gap: 10 }}>
                    {label && (
                        <Label required={required}>
                            {label}
                        </Label>
                    )}
                    {note && (
                        <InlineMessage variant='info' size="small">
                            {note}
                        </InlineMessage>
                    )}
                </Column>
            }
            { targetAttachments.map((visaAttachment, i) => 
                <PreviewContainer key={`file-preview-${i}`}>
                    <FileSizeDeleteBtnContainer>
                        <span/> {/* Dummy element */}
                        <Text>{convertBytesToMB(visaAttachment.size)} MB</Text>
                        <DeleteButton 
                            onClick={() => onAttachmentDelete?.(visaAttachment.id)}
                            style={{ cursor: 'pointer' }}
                        />
                    </FileSizeDeleteBtnContainer>
                    <Image src={visaAttachment.url} />
                </PreviewContainer>
            )}
            { isFileBeingUploaded &&
                <UploadingMessageContainer>
                    <Spinner /> 
                    <Text>
                        { t("upload.uploading") }
                    </Text>
                </UploadingMessageContainer>
            }
            <Column style={{ gap: 10 }}>
                <Column style={{ gap: 20 }}>
                    { additionalButton && additionalButton}
                    <UploadButton
                        uploadLimitInMB={sizeLimitInMB}
                        disabled={isFileBeingUploaded}
                        buttonText={uploadButtonText}
                        onFileSizeExceeded={() => {
                            dispatcher.showSnackbar(t("snackbar.under5MB"), "warning");
                        }}
                        onFileChange={(file: File) => {
                            visaAttachmentKind && onAttachmentUpload?.(file, visaAttachmentKind, id);
                        }}
                    />
                </Column>
                { enableRequiredAlert && required && targetAttachments.length === 0 &&
                    <AlertMessage>{t("inputAlert.attachment")}</AlertMessage>
                }
            </Column>
        </Container>
    );
};

export default UploadField;