//These codes are common to both ExtensionBySelf and ChangeBySelf Applications
//Used for "selCurrentZirySkk"

//Source (see at the bottom):
//https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1813510246

export enum CurrentStatusOfResidenceCode {
  Dependent = 'T44',
  SpouseOrChildOfJapaneseNational = 'T61',
  SpouseOrChildOfPermanentResident = 'T62',
  LongTermResident = 'T63',
  Student = 'T41',
  EngineerOrSpecialistInHumanitiesOrInternationalServices = 'T26',
  DesignatedActivities = 'T51',
}
