import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ACTIONS from './actions';
import { SnackbarType } from './types/snackbar';
import { ReducerContext } from './reducer';
import { wait } from './utils/utils';
import { UserRegistrationApplicationStatus } from './types/userRegistration/applicationStatus';

export const useComponentVisible = (initialVisible: boolean) => {
    const [visible, setVisible] = useState(initialVisible)
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            setVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return {ref, visible, setVisible}
}


export const useDispatcher = () => {
    const { state, dispatch } = useContext(ReducerContext);
    const createAction = (type: string, payload?: object) => ({ type, payload });
    const dispatcher = {
        login: function () {
            dispatch(createAction(ACTIONS.LOGIN));   
        },
        logout: function () {
            dispatch(createAction(ACTIONS.LOGOUT));
        },
        treatAsLoggedIn: function () {
            dispatch(createAction(ACTIONS.TREAT_AS_LOGGED_IN));
        },
        treatAsLoggedOut: function () {
            dispatch(createAction(ACTIONS.TREAT_AS_LOGGED_OUT));
        },
        startLoading: function (message?: string) {
            dispatch(createAction(ACTIONS.START_LOADING, { message }));
        },
        stopLoading: function () {
            dispatch(createAction(ACTIONS.STOP_LOADING));
        },
        showSnackbar: function (message: string, type: SnackbarType) {
            (async () => { 
                //For some reason, an await call is necessary before this method is called more than once.
                //Or, the snackbar doesn't appear in the current logic. 
                //Maybe better to change the implementation later...?
                await wait(0);
                this.clearSnackbar();
                dispatch(createAction(ACTIONS.SHOW_SNACKBAR, {message, type}));
            })();
        },
        clearSnackbar: function () {
            dispatch(createAction(ACTIONS.CLEAR_SNACKBAR));
        },
        setUserRegistrationAppStatus: function (status: UserRegistrationApplicationStatus) {
            dispatch(createAction(ACTIONS.SET_USER_REGISTRATION_APP_STATUS, { status }));
        },
        startSaving: function () {
            dispatch(createAction(ACTIONS.START_SAVING));
        },
        endSaving: function () {
            dispatch(createAction(ACTIONS.END_SAVING));
        },
        startUploading: function (uploaderId?: string) {
            dispatch(createAction(ACTIONS.START_UPLOADING, { uploaderId }));
        },
        endUploading: function (uploaderId?: string) {
            dispatch(createAction(ACTIONS.END_UPLOADING, { uploaderId }));
        },
        completeSetup: function () {
            dispatch(createAction(ACTIONS.COMPLETE_SETUP));
        }
    };

    return { state, dispatcher };
}

export const useValidation = () => {
    const { t } = useTranslation();

    return {
        isAlpha: (value: string) => {
            const result = /^[A-Za-z ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyAlpha'),
            };
        },
        isNumeric: (value: string) => {
            const result = /^[0-9 ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyNumeric'),
            };
        },
        isAlphanumeric: (value: string) => {
            const result = /^[A-Za-z0-9 ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyAlphanumeric'),
            };
        },
        isFullwidth: (value: string) => {
            const result = /^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyFullwidth'),
            };
        },
        isEmailAddress: (value: string) => {
            const result = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.isEmailAddress'),
            };
        },
        createLengthValidator: (length: number) => {
            return (value: string) => {
                const result = value.length <= length;
                return {
                    success: result,
                    message: result ? '' : t('validation.maxLengthAllowed', { number: length }),
                };
            };
        },
    };
};