import { ChangeBySelfData } from "../types/changeBySelf/data";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

//*As of July 21, 2023, these APIs are not tested at all 
//because we don't need them for the first MVP, and don't have a place to save at frontend
//We should revist this later

export const createChangeBySelfData = async (visaApplicationId: number | string, data: Partial<ChangeBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `henkou_honnins`,
      data: {
        ...data,
        "No.": '2', //TODO: Verify the value later. This is probably wrong
        visa_application_id: visaApplicationId
      }
  });

  if (hasErrorStatus(response)) 
    throwRequestError('createChangeBySelfData', response.status);

  return response.data as ChangeBySelfData;
};

export const getChangeBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `henkou_honnins/${id}`
  });

  if (hasErrorStatus(response))
    throwRequestError('getChangeBySelfData', response.status);

  return response.data as ChangeBySelfData;
};


export const updateChangeBySelfData = async (id: number | string, data: Partial<ChangeBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `henkou_honnins/${id}`,
      data
  });

  if (hasErrorStatus(response))
    throwRequestError('updateChangeBySelfData', response.status);

  return response.data as ChangeBySelfData;
};

export const deleteChangeBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `henkou_honnins/${id}`
  });

  if (hasErrorStatus(response))
    throwRequestError('deleteChangeBySelfData', response.status); 

  return response.data as ChangeBySelfData;
};


