import { UserRegistrationApplicationStatus } from "../types/userRegistration/applicationStatus";
import { UserRegistrationApplication } from "../types/userRegistration/data";
import { formatDateString } from "./utils";

const isUserRegistrationApplication = (v: unknown): v is UserRegistrationApplication => {
  if (typeof v === 'object' && v != null && v.hasOwnProperty('riyousha_touroku'))
    return true;

  return false;
}

const isUserRegistrationApplicationStatus = (v: unknown): v is UserRegistrationApplicationStatus => {
  if (typeof v === 'string' && Object.values(UserRegistrationApplicationStatus).includes(v as UserRegistrationApplicationStatus))
    return true;

  return false;
}

const checkUserRegistrationApplicationStatus = (val: UserRegistrationApplication | UserRegistrationApplicationStatus, targetStatus: UserRegistrationApplicationStatus): boolean => {
  if (isUserRegistrationApplication(val)) {
    const latestSubmission = val.last_submitted_riyousha_touroku_submission;

    if (!!latestSubmission && latestSubmission.app_status === targetStatus)
      return true;
  }

  if (isUserRegistrationApplicationStatus(val)) {
    if (val === targetStatus)
      return true;
  }

   return false;
}

export const getSubmissionDate = (userRegistrationApplication: UserRegistrationApplication): string | null => {
  const lastSubmission = userRegistrationApplication.last_submitted_riyousha_touroku_submission

  if (!lastSubmission)
    return null;

  return formatDateString(lastSubmission.submitted_at);
}

export const getApprovalDate = (userRegistrationApplication: UserRegistrationApplication): string | null => {
  const lastSubmission = userRegistrationApplication.last_submitted_riyousha_touroku_submission

  if (!lastSubmission ||
      lastSubmission.app_status !== UserRegistrationApplicationStatus.Approved)
    return null;

  return formatDateString(lastSubmission.processed_at);
}

export const isUserRegistrationApplicationApproved = (ur: UserRegistrationApplication | UserRegistrationApplicationStatus): boolean => {
  return checkUserRegistrationApplicationStatus(ur, UserRegistrationApplicationStatus.Approved);
}

export const isUserRegistrationApplicationDenied = (ur: UserRegistrationApplication | UserRegistrationApplicationStatus): boolean => {
  return checkUserRegistrationApplicationStatus(ur, UserRegistrationApplicationStatus.Denied);
}

export const isUserRegistrationApplicationDraft = (ur: UserRegistrationApplication | UserRegistrationApplicationStatus): boolean => {
  return checkUserRegistrationApplicationStatus(ur, UserRegistrationApplicationStatus.Working);
}

export const isUserRegistrationApplicationBeingProcessed = (ur: UserRegistrationApplication | UserRegistrationApplicationStatus): boolean => {
  return checkUserRegistrationApplicationStatus(ur, UserRegistrationApplicationStatus.Applying);
}

export const extractUserRegistrationAppStatus = (userRegistrationApplication: UserRegistrationApplication | null | undefined): UserRegistrationApplicationStatus => {
  if (!userRegistrationApplication)
    return UserRegistrationApplicationStatus.Working;
  
  const latestSubmission = userRegistrationApplication.last_submitted_riyousha_touroku_submission;

  if (!latestSubmission)
    return UserRegistrationApplicationStatus.Working;

  return latestSubmission.app_status;
}