import axios, { AxiosResponse } from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  timeout: 5000,
});

export const hasErrorStatus = (response: AxiosResponse<any, any>): boolean => {
  return (response.status < 200 && response.status >= 300);
}

export const throwRequestError = (name: string, status: number) => {
  throw new Error(`Request (${name}) failed with status code ${status}`);
}