import { FunctionComponent, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Text from '../text/Text';
import { ReactComponent as ExpandIcon } from '../../assets/icon-expand.svg';
import { ReactComponent as AlertIcon } from '../../assets/icon-alert.svg';

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
    active?: boolean;
    label?: string;
    isError?: boolean;
    iconComponent?: ReactNode;
    allowContentOverflow?: boolean;
}

interface LabelProps {
    active?: boolean;
}

const Container = styled.div`
  border: 1px solid #D5D5D5;

  &:not(:first-child) {
    border-top: none;
  }
`;

const Button = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 6px 8px 6px;
  box-sizing: border-box;
  transition: transform 400ms ease;
`;

const Label = styled.div<LabelProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 10px 0 20px;
  background: ${(props: LabelProps) => props.active ? '#C8E0FF' : '#FFFFFF'};
  
  > ${Button} {
    transform: ${(props: LabelProps) => props.active ? 'rotate(180deg)' : 'none'};
  }
`;

const Content = styled.div`
  height: auto;
  overflow: hidden;
  transition: all 400ms ease-in-out;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

const Accordion: FunctionComponent<AccordionProps> = ({
    active,
    label,
    children,
    iconComponent,
    onClick,
    ...props
}) => {
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            const container = contentRef.current as HTMLDivElement;
            if (active) {
                container.style.height = `${container.scrollHeight}px`;
                setTimeout(() => {
                    container.style.height = 'auto';

                    if (props.allowContentOverflow)
                        container.style.overflow = 'visible';

                }, 400)
            } else {
                container.style.height = '0';
                container.style.overflow = 'hidden';
            }
        }
    }, [active]);

    return (
        <Container {...props}>
            <Label active={active} onClick={onClick}>
                <Inner>
                    { props.isError && <AlertIcon /> }
                    { iconComponent }
                    <Text style={{ color: active ? '#024EAC' : '#444444' }}>
                        {label}
                    </Text>
                </Inner>
                <Button>
                    <ExpandIcon />
                </Button>
            </Label>
            <Content ref={contentRef}>
                {children}
            </Content>
        </Container>
    );
};

export default Accordion;