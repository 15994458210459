import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../components/layout/Page";
import Text from "../../components/text/Text";
import { Trans, useTranslation } from "react-i18next";
import PageHeading from "../../components/text/PageHeading";
import Column from "../../components/layout/Column";
import DisplayLabel from "../../components/form/DisplayLabel";
import { useDispatcher } from "../../hooks";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";
import { UserRegistration } from "../../types/userRegistration/data";
import { StatusOfResidenceOfUser } from "../../types/user/data";
import { getCurrentUser } from "../../apis/user";
import { getLabelsFromAddressCode } from "../../utils/visaFormDataHelper";
import InlineMessage from "../../components/form/InlineMessage";
import { convertStatusOfResidenceToTransKey } from "../../utils/userDataHelper";
import { Gender } from "../../types/userRegistration/value";
import { getApprovalDate, isUserRegistrationApplicationApproved } from "../../utils/userRegistrationHelper";

interface ProfilePageProps {}

const LocalPage = styled(Page)`
  background-color: #ffffff;
  padding: 0 0 30px 0;
  gap: 20px;
`;

const AccountSecContainer = styled(Column)`
  margin-bottom: 10px;
`;

const HeadingInfoContainer = styled(Column)`
  padding: 20px;
  gap: 20px;
`;

const SiteAnchor = styled.a`
  color: #0069EC;
`;

const DataContainer = styled.div`
  width: 100%;
`;

const GovAccountSecContainer = styled(Column)`
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 10px 20px 10px 20px;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

const LocalDisplayLabel = styled(DisplayLabel)`
  word-break: break-all;
`;

const LocalText = styled(Text)`
  word-break: break-all;
`;


const AccountPage: FunctionComponent<ProfilePageProps> = () => {
  const { t } = useTranslation();
  const { dispatcher } = useDispatcher();

  const [isRegistrationDone, setIsRegistrationDone] = useState(false);

  const [email, setEmail] = useState("");
  const [registrationDate, setRegistrationDate] = useState("-");
  const [statusOfResidence, setStatusOfResidence] = useState<StatusOfResidenceOfUser>();
  const [emailForContact, setEmailForContact] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [townStreetBuilding, setTownStreetBuilding] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [residenceCardNumber, setResidenceCardNumber] = useState("");

  useEffect(() => {
    (async () => {
      try {
        dispatcher.startLoading();

        const user = await getCurrentUser();
        const userRegistrationApplication = await getLatestUserRegistrationApplication();
        const result: UserRegistration = userRegistrationApplication.riyousha_touroku;
      
        const [familyName, givenName, ...middleNameArr ] = 
          result["WCFDS010Dto:hdnUserInfoName"].split(" ");
        const address = getLabelsFromAddressCode(
          result["WCFDS010Dto:hdnSearchedAddress"]
        );

        if (isUserRegistrationApplicationApproved(userRegistrationApplication)) {
          setIsRegistrationDone(true);
          setRegistrationDate(getApprovalDate(userRegistrationApplication) ?? '-');
        }

        setEmail(user.email);
        setStatusOfResidence(user.status_of_residence); 
        setEmailForContact(result["WCFDS010Dto:txtMailAddress"]);
        setFamilyName(familyName);
        setGivenName(givenName);
        setMiddleName(middleNameArr.join(" "));
        setGender(result["WCFDS010Dto:hdnSex"]);
        setBirthdate(
          [
            result["WCFDS010Dto:hdnDateOfBirthYear"],
            result["WCFDS010Dto:hdnDateOfBirthMonth"],
            result["WCFDS010Dto:hdnDateOfBirthDay"],
          ].join("/")
        );
        setPhoneNumber(result["WCFDS010Dto:txtUserTelNo"]);
        setPrefecture(address.prefecture
          ? t(address.prefecture)
          : ""
        );
        setMunicipality(address.municipality
          ? t(address.municipality)
          : ""
        );
        setTownStreetBuilding(result["WCFDS010Dto:txtDetailHomeAddress"]);
        setResidenceCardNumber(result["WCFDS010Dto:txtZiryCardNum"]);
      } catch (e) {
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <LocalPage>
      <AccountSecContainer>
        <HeadingInfoContainer>
          <PageHeading>{t("accountPage.account")}</PageHeading>
        </HeadingInfoContainer>
        <DataContainer>
          <ItemRow>
            <LocalDisplayLabel>
              {t("accountPage.email")}
            </LocalDisplayLabel>
            <LocalText>{email}</LocalText>
          </ItemRow>
        </DataContainer>
      </AccountSecContainer>

      <GovAccountSecContainer>
        <HeadingInfoContainer>
          <PageHeading>{t("accountPage.govAccount")}</PageHeading>
          <InlineMessage variant="caution" size="base">
            <Column style={{ gap: 10 }}>
              <Text>{t("accountPage.caution1")}</Text>
              <Text style={{ display: 'block'}}>
                <Trans
                  i18nKey={t("accountPage.caution2")}
                  components={{
                    siteLink: (
                      <SiteAnchor
                        href="https://www.ras-immi.moj.go.jp/WC01/WCAAS010/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link to a RAS website page
                      </SiteAnchor>
                    )}
                  }
                />
              </Text> 
            </Column>
          </InlineMessage>
          <Text>
            { isRegistrationDone
                ? `${t("accountPage.registered")} ${registrationDate}`
                : t("accountPage.noInformation")
            }
          </Text>
        </HeadingInfoContainer>
      
        { isRegistrationDone &&
          <DataContainer>
            <ItemRow>
              <LocalDisplayLabel>
                {t("accountPage.currentStatusOfResidence.label")}
              </LocalDisplayLabel>
              <LocalText>
                { statusOfResidence &&
                  t("accountPage.currentStatusOfResidence.value." + 
                    convertStatusOfResidenceToTransKey(statusOfResidence))
                }
              </LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.emailForContact")}</LocalDisplayLabel>
              <LocalText>{emailForContact}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.familyName")}</LocalDisplayLabel>
              <LocalText>{familyName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.givenName")}</LocalDisplayLabel>
              <LocalText>{givenName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.middleName")}</LocalDisplayLabel>
              <LocalText>{middleName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.gender")}</LocalDisplayLabel>
              <LocalText>
                { gender === Gender.Male && t("accountPage.male") }
                { gender === Gender.Female && t("accountPage.female") }
              </LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.birthdate")}</LocalDisplayLabel>
              <LocalText>{birthdate}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("accountPage.phoneNumber")}</LocalDisplayLabel>
              <LocalText>{phoneNumber}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("accountPage.currentResidencePrefecture")}
              </LocalDisplayLabel>
              <LocalText>{prefecture}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("accountPage.currentResidenceMunicipality")}
              </LocalDisplayLabel>
              <LocalText>{municipality}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("accountPage.currentResidenceTownStreetBuilding")}
              </LocalDisplayLabel>
              <LocalText>{townStreetBuilding}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("accountPage.residenceCardNumber")}
              </LocalDisplayLabel>
              <LocalText>{residenceCardNumber}</LocalText>
            </ItemRow>
          </DataContainer>
        }
      </GovAccountSecContainer>
    </LocalPage>
  );
};

export default AccountPage;
